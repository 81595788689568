import { GroupRowVM } from '@elexient/elexiapp.bits.shared'
import { computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { SessionsListVM } from './SessionsListVM'

export class SessionGroupRowVM extends GroupRowVM<RootStore, SessionsListVM> {
  @computed
  public get displayName(): string {
    return this.name.substring(1)
  }

  public get color(): string {
    return '#c00000'
  }

  public get height(): number{
    return 40
  }
}
