import agent from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { ISessionDTO } from '../dtos/ISessionDTO'

export class SessionsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: ISessionDTO) {
    this.rootStore.sessionsStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.SESSION_SAVE, dto)
  }

  public async saveOnServer(dto: ISessionDTO) {
    const savedDTO = await agent.Sessions.save(dto)
    this.rootStore.sessionsStore.updateFromServer(savedDTO)
  }

  public delete(dto: ISessionDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }
}
