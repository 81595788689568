import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  IonButton,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonText,
} from '@ionic/react'
import { WorkoutsStore } from '../../store/WorkoutsStore'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { reorderTwo, trashOutline } from 'ionicons/icons'
import { ItemRowVM } from '../../view-models/edit/ItemRowVM'

interface Props {
  workoutsStore?: WorkoutsStore
}

const ItemsSortableList: React.FC<Props> = ({ workoutsStore }) => {
  const editVM = workoutsStore.editVM
  if (!editVM) return null
  if (editVM.currentTabIndex !== '1') return null
  const listVM = editVM.itemsListVM
  if (!listVM) return null
  if (!listVM.sortMode) return null

  const renderDeleteOption = (row) => {
    return (
      <IonItemOption
        onClick={(e) => {
          e.stopPropagation()
          row.delete()
        }}
      >
        <IonText>Delete</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  const renderRow = (row: ItemRowVM, idx) => {
    return (
      <IonItem lines='none' className='normal-row sort-mode' onClick={() => row.click()} key={row.key}>
        <div className='sort-handle'>
          <IonIcon icon={reorderTwo} size='small' />
        </div>
        <div className='main-cell'>{row.displayName}</div>
        <div className='middle-cell'>{row.schemeInfo}</div>
        <div className='right-cell'>{row.rest}</div>
        <div className='right-cell'>
          <IonIcon
            onMouseDown={(e) => {
              e.stopPropagation()
              row.delete()
            }}
            icon={trashOutline}
          />
        </div>
      </IonItem>
    )
  }

  const SortableItem = SortableElement(({ value, index }) => {
    return <div>{renderRow(value, index)}</div>
  })

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    )
  })

  return (
    <div className='list'>
      <SortableList
        items={listVM.allRows
          .filter((e) => e.rowType === 'normal')
          .sort((a, b) => ((a as ItemRowVM).rank < (b as ItemRowVM).rank ? -1 : 0))}
        onSortEnd={({ oldIndex, newIndex }) => listVM.sortRows(oldIndex, newIndex)}
      />
    </div>
  )
}

export default inject('workoutsStore')(observer(ItemsSortableList))
