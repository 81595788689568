import { computed, action } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { Workout } from '../../aggregate/Workout'
import { WorkoutsListVM } from './WorkoutsListVM'
import { RootStore } from '../../../stores/RootStore'

export class WorkoutRowVM extends RowVM<RootStore, WorkoutsListVM, Workout> {
  constructor(rootStore: RootStore, listVM: WorkoutsListVM, exercise: Workout, self: any) {
    super(rootStore, listVM, exercise, self)
  }

  @computed
  public get keyword(): string {
    return this.record.Name
  }

  @computed
  public get name(): string {
    return this.record.Name
  }

  @computed
  public get key(): string {
    return this.record.WorkoutGuid
  }

  @action
  public click() {
    this.rootStore.appStore.history.push('/workoutedit/' + this.key)
  }

  @computed
  public get notes(): string {
    return this.record.Notes
  }

  @computed
  public get hasNotes(): boolean {
    return this.record.hasNotes
  }

  public get height(): number {
    if (this.isHidden) return 0
    return 44
  }
}
