import { RootStore } from '../../../stores/RootStore'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { SetRowVM } from './SetRowVM'
import { SetGroupRowVM } from './SetGroupRowVM'
import { Set } from '../../aggregate/Set'
import { SessionEditVM } from './SessionEditVM'
import { arrayMove } from 'react-sortable-hoc'
import { ItemTypeEnum } from '../../enums/ItemTypeEnum'

export class SetsListVM extends ListVM<RootStore, Set, SetGroupRowVM, SetRowVM, undefined> {
  constructor(rootStore: RootStore, editVM: SessionEditVM) {
    super(rootStore, SetRowVM, SetGroupRowVM, undefined)
    makeObservable(this)
    this.parentVM = editVM
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    this.atTopCheckTimeout = 500
    this.setGroups('exerciseName', '')
    this.addReaction(
      reaction(
        () => this.rootStore.appStore.listHeight,
        () => this.setHeight(this.computedListHeight),
        { delay: 200 }
      )
    )
    this.setHeight(this.computedListHeight)
    setInterval(() => runInAction(() => (this.tick = this.tick + 1)), 1000)
  }

  public parentVM: SessionEditVM
  @observable public tick: number = 0

  protected getRecords() {
    return this.parentVM.session.SessionExercises.map((e) => e.Sets).flat(1)
  }

  protected rowVMSortFunction = (a: SetRowVM, b: SetRowVM): number => {
    let aVal = a.setNumber
    let bVal = b.setNumber
    if (aVal < bVal) return -1
    return 0
  }

  private get computedListHeight() {
    return this.rootStore.appStore.listHeight
  }

  @action
  public sortRows(oldIndex: number, newIndex: number) {
    const oldRows = this.parentVM.session.SessionExercises.sort((a, b) => (a.Rank < b.Rank ? -1 : 0)).map((e) => e)
    const newRows = arrayMove(oldRows, oldIndex, newIndex)
    // newRows.forEach((e, idx) => e.setRank(idx + 1))
    // this.reloadRows()
  }
}
