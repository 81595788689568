import React from 'react'
import { inject, observer } from 'mobx-react'
import { IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonText } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { WorkoutsStore } from '../../store/WorkoutsStore'
import { ItemRowVM } from '../../view-models/edit/ItemRowVM'
import LongPress from '../../../utils/LongPress'

interface Props {
  workoutsStore?: WorkoutsStore
}

const ItemsList: React.FC<Props> = ({ workoutsStore }) => {
  const editVM = workoutsStore.editVM
  if (!editVM) return null
  if (editVM.currentTabIndex !== '1') return null
  const listVM = editVM.itemsListVM
  if (!listVM) return null
  if (listVM.sortMode) return null

  const renderNormalRow = (row: ItemRowVM, index, style) => {
    const style2 = {
      ...style,
      borderLeft: 'none',
    }
    return (
      <LongPress onLongPress={() => listVM.enterSortMode()} key={row.key}>
        <IonItemSliding style={style2} onClick={() => row.click()}>
          <IonItem lines='none' className='normal-row' onClick={() => row.click()}>
            <div className='main-cell'>{row.displayName}</div>
            <div className='middle-cell'>{row.schemeInfo}</div>
            <div className='right-cell'>{row.rest}</div>
          </IonItem>
          <IonItemOptions side='end'>{renderDeleteOption(row)}</IonItemOptions>
        </IonItemSliding>
      </LongPress>
    )
  }

  const renderDeleteOption = (row) => {
    return (
      <IonItemOption
        onClick={(e) => {
          e.stopPropagation()
          row.delete()
        }}
      >
        <IonText>Delete</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => undefined}
    />
  )
}

export default inject('workoutsStore')(observer(ItemsList))
