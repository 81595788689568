import { observable, makeObservable, action } from 'mobx'
import { serialize, serializable, object } from 'serializr'
import { IItemDTO } from '../dtos/IItemDTO'
import uuid from 'uuid'
import { Scheme } from './Scheme'
import { Exercise } from './Exercise'
import { IDTO } from '../../shared/IDTO'
import { IExerciseDTO } from '../dtos/IExerciseDTO'
import { ISchemeDTO } from '../dtos/ISchemeDTO'

export class Item implements IItemDTO, IDTO {
  public static create(): Item {
    const it = new Item()
    it.ItemGuid = uuid()
    return it
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public ItemGuid: string
  @serializable @observable public ExerciseGuid: string
  @serializable @observable public SchemeGuid: string
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public Rank: number = 0
  @serializable(object(Exercise)) @observable public Exercise: Exercise
  @serializable(object(Scheme)) @observable public Scheme: Scheme

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }

  @action
  public setExercise(ex: IExerciseDTO, sch: ISchemeDTO) {
    this.ExerciseGuid = ex.ExerciseGuid
    this.SchemeGuid = sch.SchemeGuid
    this.Exercise = serialize(Exercise, ex)
    this.Scheme = serialize(Scheme, sch)
  }

  public toDTO(): IItemDTO {
    return serialize(this)
  }

  @action
  public setRank(val: number): void {
    this.Rank = val
  }
}
