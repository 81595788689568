import React from 'react'
import { Observer, observer } from 'mobx-react'
import { IonIcon, IonItem } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { ItemRowVM } from '../../view-models/edit/ItemRowVM'
import { ItemEditVM } from '../../view-models/edit/ItemEditVM'
import { SchemeGroupRowVM } from '../../view-models/edit/SchemeGroupRowVM'
import { chevronForward, chevronDown } from 'ionicons/icons'
import { WorkoutGroupRowVM } from '../../view-models/list/WorkoutGroupRowVM'

interface Props {
  itemEditVM: ItemEditVM
}

const SchemesList: React.FC<Props> = ({ itemEditVM }) => {
  const editVM = itemEditVM
  if (!editVM) return null
  const listVM = editVM.schemesListVM
  if (!listVM) return null

  const renderNormalRow = (row: ItemRowVM, index, style) => {
    const style2 = {
      ...style,
      borderLeft: 'none',
    }
    return (
      <IonItem key={row.key} lines='none' className={'normal-row'} onClick={() => row.click()} style={style2}>
        <div className='main-cell'>{row.displayName}</div>
      </IonItem>
    )
  }

  const renderRightIcon = (row: SchemeGroupRowVM) => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = (row: SchemeGroupRowVM) => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }
  
  const renderGroupRow = (row: SchemeGroupRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.isCollapsed + row.isInvView}>
        {() => (
          <div style={style} className={row.rowType} onClick={() => row.click()}>
            <div className='main-cell'>{row.displayName}</div>
            {renderRightIcon(row)}
            {renderDownIcon(row)}
          </div>
        )}
      </Observer>
    )
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
    />
  )
}

export default observer(SchemesList)
