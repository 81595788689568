import { observable, action, computed, makeObservable } from 'mobx'
import moment from 'moment'
import { RootStore } from 'src/stores/RootStore'
import { SelectableColors } from '../../../shared/SelectableColors'
import { Session } from '../../aggregate/Session'
import { SessionStatusEnumName } from '../../enums/SessionStatusEnumName'
import { SessionsService } from '../../services/SessionsService'
import { SetEditVM } from './SetEditVM'
import { SetRowVM } from './SetRowVM'
import { SetsListVM } from './SetsListVM'

export class SessionEditVM {
  private rootStore: RootStore
  private contentRef: HTMLIonContentElement
  private saveTO: NodeJS.Timeout

  constructor(rootStore: RootStore, session: Session, isNew: boolean = false) {
    makeObservable(this)
    this.rootStore = rootStore
    this.loadSession(session, isNew)
    this.setsListVM = new SetsListVM(this.rootStore, this)
  }

  @observable public deleteConfirmShown: boolean = false
  @observable public dayOfMonth: number = 1
  public isNew: boolean = false
  @observable public currentTabIndex: string = '0'
  public session: Session
  public setsListVM: SetsListVM
  @observable public setEditVM: SetEditVM
  @observable public isMenuOpen: boolean = false
  @observable public editMode: boolean = false
  public menuEvent: any = undefined

  @action
  public enterEditMode() {
    this.editMode = true
    this.closeMenu()
  }

  @action
  public openMenu(event) {
    this.isMenuOpen = true
    this.menuEvent = event
  }

  @action
  public closeMenu() {
    this.isMenuOpen = false
  }

  @action
  public loadSession(session: Session, isNew: boolean) {
    this.session = session.clone()
    this.isNew = Boolean(isNew)
    console.log(session)
  }

  @action
  public setName(val) {
    this.session.setName(val)
    this.save()
  }

  @computed
  public get name(): string {
    return this.session.Name
  }

  @action
  public setNotes(val) {
    this.session.setNotes(val)
    this.save()
  }

  @computed
  public get notes(): string {
    return this.session.Notes
  }

  @computed
  public get localSessionDate() {
    return this.session.localSessionDate
  }

  @action
  public setSessionDate(val: moment.Moment) {
    this.session.setSessionDate(val)
    this.save()
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @computed
  public get pageTitle(): string {
    if (this.isNew) return 'New Session'
    return this.session.Name
  }

  @action
  public next() {
    const firstUndone = this.setsListVM.allRows.find((e: SetRowVM) => e.isCompleted === false) as SetRowVM
    if (!firstUndone) return
    firstUndone.toggleCompleted()
    this.setsListVM.reloadRows()
  }

  public save(goBack: boolean = false) {
    if (this.saveTO) clearTimeout(this.saveTO)
    this.saveTO = setTimeout(() => this.doSave(goBack), 1000)
  }

  @action
  public doSave(goBack: boolean) {
    const dto = this.session.toDTO()
    const svc = new SessionsService(this.rootStore)
    svc.save(dto)
    if (goBack) this.rootStore.appStore.handleGoBack()
  }

  @action
  public showDeleteConfirm() {
    this.closeMenu()
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  public delete() {
    const svc = new SessionsService(this.rootStore)
    svc.delete(this.session.toDTO())
    this.rootStore.appStore.handleGoBack()
  }

  @action
  public goBack() {
    this.rootStore.appStore.handleGoBack()
  }

  public get colors() {
    return SelectableColors.colors
  }

  public setContentRef(e: HTMLIonContentElement) {
    this.contentRef = e
  }

  @action
  public setCurrentTab(val: string) {
    if (this.currentTabIndex === val) return
    this.currentTabIndex = val
    this.contentRef.scrollToTop()
  }

  @computed
  public get saveFabShown(): boolean {
    if (this.currentTabIndex === '0') return true
    return false
  }

  @computed
  public get nextFabShown(): boolean {
    if (this.currentTabIndex === '1') return true
    return false
  }

  @action
  public toggleSetCompleted(exerciseGuid: string, setGuid: string) {
    this.session.toggleExerciseSetCompleted(exerciseGuid, setGuid)
    this.save()
  }

  @action
  public setSetActualValue(exerciseGuid: string, setGuid: string, val: number) {
    this.session.setSetActualValue(exerciseGuid, setGuid, val)
    this.save()
  }

  @action
  public setSetActualReps(exerciseGuid: string, setGuid: string, val: number) {
    this.session.setSetActualReps(exerciseGuid, setGuid, val)
    this.save()
  }

  public get statuses() {
    return SessionStatusEnumName.list
  }

  @computed
  public get statusId(): number {
    return this.session.SessionStatusId
  }

  @action
  public setStatus(val: number) {
    this.session.setStatus(val)
  }

  @action
  public deleteExercise(guid: string) {
    // this.session.Items.filter((e) => e.ExerciseGuid === guid).forEach((e) => e.markAsDeleted())
  }
}
