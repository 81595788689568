import superagentPromise from 'superagent-promise'
import _superagent from 'superagent'

const superagent = superagentPromise(_superagent, global.Promise)
const API_ROOT = process.env.REACT_APP_API_URL + '/api'
const handleErrors = (err) => err
const responseBody = (res) => res.body

const requests = {
  get: (url, token) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use((req) => {
        let tk = null
        if (token) tk = token
        if (tk) req.set('Authorization', `Bearer ${tk}`)
      })
      .end(handleErrors)
      .then(responseBody),

  post: (url, body = {}, token = null) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use((req) => {
        let tk = null
        if (token) tk = token
        else if (window && window.token) tk = window.token
        req.set('Authorization', `Bearer ${tk}`)
      })
      .end(handleErrors)
      .then(responseBody),
}

const Exercises = {
  getAll: (token) => requests.get('/exercises/all', token),
  save: (form) => requests.post('/exercises/save', { ...form }),
}

const Workouts = {
  getAll: (token) => requests.get('/workouts/all', token),
  save: (form) => requests.post('/workouts/save', { ...form }),
}

const Sessions = {
  getAll: (token) => requests.get('/sessions/all', token),
  save: (form) => requests.post('/sessions/save', { ...form }),
}

const Boards = {
  getAll: (token) => requests.get('/boards/all', token),
  save: (form) => requests.post('/boards/save', { ...form }),
  generateInvitationCode: (form) => requests.post('/boards/generateinvitationcode', { ...form }),
  joinBoard: (invitationCode) => requests.post('/boards/join', { invitationCode: invitationCode }),
}

const BoardInvitations = {
  getAll: (token) => requests.get('/boardinvitations/all', token),
  save: (form) => requests.post('/boardinvitations/save', { ...form }),
}

export default {
  Boards,
  BoardInvitations,
  Exercises,
  Workouts,
  Sessions
}
