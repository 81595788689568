import { ListVM } from '@elexient/elexiapp.bits.shared'
import { action, reaction } from 'mobx'
import { RefresherEventDetail } from '@ionic/core'
import { RootStore } from '../../../stores/RootStore'
import { Workout } from '../../aggregate/Workout'
import { WorkoutGroupRowVM } from './WorkoutGroupRowVM'
import { WorkoutRowVM } from './WorkoutRowVM'

export class WorkoutsListVM extends ListVM<
  RootStore,
  Workout,
  WorkoutGroupRowVM,
  WorkoutRowVM,
  undefined
> {
  constructor(rootStore: RootStore) {
    super(rootStore, WorkoutRowVM, WorkoutGroupRowVM, undefined)
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    this.atTopCheckTimeout = 500
    this.setGroups('', '')
    reaction(
      () => this.rootStore.appStore.listHeight,
      () => this.setHeight(this.rootStore.appStore.listHeight),
      { delay: 200 }
    )
    reaction(
      () => this.rootStore.workoutsStore.recordJustUpdated,
      () => this.reloadRows()
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  protected getRecords() {
    return this.rootStore.workoutsStore.currentBoardRecords
  }

  protected sortFunction = (a: Workout, b: Workout): number => {
    const aVal = a.Name.toLowerCase()
    const bVal = b.Name.toLowerCase()
    if (aVal < bVal) return -1
    return 0
  }

  @action
  public async refresh(e: CustomEvent<RefresherEventDetail>) {
    await this.rootStore.exercisesStore.loadData()
    if (e) (e.target as any).complete()
  }

  @action
  public addWorkout() {
    let url = '/workoutedit'
    this.rootStore.appStore.navigateTo(url + '/new')
  }
}
