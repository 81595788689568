import { ListVM } from '@elexient/elexiapp.bits.shared'
import { ExerciseRowVM } from './ExerciseRowVM'
import { ExerciseGroupRowVM } from './ExerciseGroupRowVM'
import { action, computed, observable, reaction } from 'mobx'
import { RefresherEventDetail } from '@ionic/core'
import { RootStore } from '../../../stores/RootStore'
import { Exercise } from '../../aggregate/Exercise'

export class ExercisesListVM extends ListVM<RootStore, Exercise, ExerciseGroupRowVM, ExerciseRowVM, undefined> {
  constructor(rootStore: RootStore) {
    super(rootStore, ExerciseRowVM, ExerciseGroupRowVM, undefined)
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    this.atTopCheckTimeout = 500
    this.setGroups('', '')
    reaction(
      () => this.rootStore.appStore.listHeight,
      () => this.setHeight(this.rootStore.appStore.listHeight),
      { delay: 200 }
    )
    reaction(
      () => this.rootStore.exercisesStore.recordJustUpdated,
      () => this.reloadRows()
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  protected getRecords() {
    return this.rootStore.exercisesStore.currentBoardRecords
  }

  protected aggregateSortFunction = (a: Exercise, b: Exercise): number => {
    const aVal = a.Name.toLowerCase()
    const bVal = b.Name.toLowerCase()
    if (aVal < bVal) return -1
    return 0
  }

  @action
  public async refresh(e: CustomEvent<RefresherEventDetail>) {
    await this.rootStore.exercisesStore.loadData()
    if (e) (e.target as any).complete()
  }

  @action
  public addExercise() {
    let url = '/exerciseedit'
    this.rootStore.appStore.navigateTo(url + '/new')
  }
}
