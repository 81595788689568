import React, { Component } from 'react'

class LongPress extends Component<any, any> {
  shouldShortPress = true
  moved = false

  state = {
    touch: true,
  }

  static defaultProps = {
    time: 500,
  }
  timeout: NodeJS.Timer

  componentDidMount() {
    // try {
    //   document.createEvent('TouchEvent')
    // } catch (e) {
    //   // touch is not available, disable handlers
    //   this.setState({ touch: false })
    // }
  }

  componentWillUnmount() {
    this.cancelTimeout()
  }

  startTimeout = (e) => {
    this.timeout = setTimeout(() => this.longPressed(e), this.props.time)
  }

  longPressed = (e) => {
    this.shouldShortPress = false
    if (this.props.onLongPress && this.moved === false) {
      this.props.onLongPress(e)
    }
  }

  cancelTimeout = () => {
    clearTimeout(this.timeout)
  }

  onTouchStart = e => {
    this.shouldShortPress = true
    this.moved = false
    this.startTimeout(e)
    if (typeof this.props.onTouchStart === 'function') {
      this.props.onTouchStart(e)
    }
  }

  onTouchEnd = e => {
    this.cancelTimeout()
    if (this.props.onPress && this.shouldShortPress && this.moved === false) {
      this.props.onPress(e)
    }
    if (typeof this.props.onTouchEnd === 'function') {
      this.props.onTouchEnd(e)
    }
  }

  onTouchCancel = e => {
    this.cancelTimeout()
    if (typeof this.props.onTouchCancel === 'function') {
      this.props.onTouchCancel(e)
    }
  }

  onMove = e => {
    this.moved = true
    if (typeof this.props.onTouchMove === 'function') {
      this.props.onTouchMove(e)
    }
  }

  render() {
    const { disabled } = this.props
    const { touch } = this.state
    const children: any = this.props.children

    if (!touch || disabled) {
      return children
    }

    const props = {
      onContextMenu: e => e.preventDefault(),
      onMouseDown: this.onTouchStart,
      onMouseUp: this.onTouchCancel,
      onMouseLeave: this.onTouchCancel,
      onTouchStart: this.onTouchStart,
      onTouchEnd: this.onTouchEnd,
      onTouchMove: this.onMove,
      onTouchCancel: this.onTouchCancel,
      style: {
        ...children.props.style,
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
      },
    }

    return React.cloneElement(children, { ...children.props, ...props })
  }
}

export default LongPress
