import React from 'react'
import { inject, observer } from 'mobx-react'
import { IonLabel, IonSegment, IonSegmentButton, IonBadge, IonIcon } from '@ionic/react'
import { AppStore } from 'src/stores/AppStore'
import { list, informationCircle, compass, pricetags } from 'ionicons/icons'
import { WorkoutsStore } from '../../store/WorkoutsStore'

interface Props {
  workoutsStore?: WorkoutsStore
  appStore?: AppStore
}

const TabButtons: React.FC<Props> = ({ workoutsStore, appStore }) => {
  const { editVM } = workoutsStore
  if (!editVM) return null

  return (
    <div id='TabButtons'>
      <IonSegment
        value={editVM.currentTabIndex}
        scrollable
        onIonChange={(e) => editVM.setCurrentTab(e.detail.value)}
        disabled={editVM.tabsDisabled}
      >
        <IonSegmentButton value='0' className='info' layout='icon-top'>
          <IonIcon icon={informationCircle} />
          <IonLabel>Info</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='1' layout='icon-top'>
          <IonIcon icon={list} />
          <IonLabel>Exercises</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='2' layout='icon-top'>
          <IonIcon icon={compass} />
          <IonLabel>Tags</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='3' layout='icon-top'>
          <IonIcon icon={compass} />
          <IonLabel>History</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </div>
  )
}

export default inject('workoutsStore', 'appStore')(observer(TabButtons))
