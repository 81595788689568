import { RootStore } from '../../../stores/RootStore'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { action, makeObservable, observable, reaction } from 'mobx'
import { ItemRowVM } from './ItemRowVM'
import { ItemGroupRowVM } from './ItemGroupRowVM'
import { Item } from '../../aggregate/Item'
import { WorkoutEditVM } from './WorkoutEditVM'
import { arrayMove } from 'react-sortable-hoc'

export class ItemsListVM extends ListVM<RootStore, Item, ItemGroupRowVM, ItemRowVM, undefined> {
  constructor(rootStore: RootStore, editVM: WorkoutEditVM) {
    super(rootStore, ItemRowVM, ItemGroupRowVM, undefined)
    makeObservable(this)
    this.parentVM = editVM
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    this.atTopCheckTimeout = 500
    this.setGroups('', '')
    this.addReaction(
      reaction(
        () => this.rootStore.appStore.listHeight,
        () => this.setHeight(this.rootStore.appStore.listHeight),
        { delay: 200 }
      )
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  @observable public sortMode: boolean = false
  public parentVM: WorkoutEditVM

  @action
  public enterSortMode() {
    const isSlidOpen = document.getElementsByClassName('item-sliding-active-slide').length > 0
    if (isSlidOpen) return
    this.sortMode = true
  }

  @action
  public exitSortMode() {
    this.sortMode = false
  }

  protected getRecords() {
    return this.parentVM.workout.Items.filter((e) => e.IsDeleted !== true)
  }

  protected aggregateSortFunction = (a: Item, b: Item): number => {
    const aVal = a.Rank
    const bVal = b.Rank
    if (aVal < bVal) return -1
    return 0
  }

  @action
  public addItem() {
    // this.editVM
  }

  @action
  public sortRows(oldIndex: number, newIndex: number) {
    const oldRows = this.parentVM.workout.Items.sort((a, b) => (a.Rank < b.Rank ? -1 : 0)).map((e) => e)
    const newRows = arrayMove(oldRows, oldIndex, newIndex)
    newRows.forEach((e, idx) => e.setRank(idx + 1))
    this.reloadRows()
  }
}
