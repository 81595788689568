import React from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonTitle,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { observer } from 'mobx-react'
import { close, checkmarkSharp } from 'ionicons/icons'
import BackIcon from 'src/shared/BackIcon'
import { SchemeEditVM } from '../../view-models/edit/SchemeEditVM'
import SchemeEditForm from './SchemeEditForm'

interface Props {
  vm: SchemeEditVM
}

const SchemeEditModal: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  return (
    <>
      <IonModal id='SchemeEditModal' isOpen={vm.shown} onDidDismiss={() => vm.hide()}>
        <IonHeader id='ItemNewModal_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => vm.hide()}>
                <BackIcon />
              </IonButton>
            </IonButtons>
            <IonTitle>Add Scheme</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={() => vm.hide()}>
                <IonIcon icon={close} />
              </IonButton>
              <IonButton onClick={() => vm.save()} disabled={!vm.isValid}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <SchemeEditForm vm={vm} />
        </IonContent>
        <IonFab vertical='bottom' horizontal='end'>
          <IonFabButton
            disabled={!vm.isValid}
            color='tertiary'
            onClick={() => vm.save()}
            style={{
              marginTop: '-60px',
              marginLeft: '-60px',
            }}
          >
            <IonIcon icon={checkmarkSharp} />
          </IonFabButton>
        </IonFab>
      </IonModal>
    </>
  )
}

export default observer(SchemeEditModal)
