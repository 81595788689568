import { observable, makeObservable } from 'mobx'
import { IExerciseDTO } from '../dtos/IExerciseDTO'
import { serializable, serialize } from 'serializr'

export class Exercise implements IExerciseDTO {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public ExerciseGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public Notes: string = ''
  @serializable @observable public BoardId: number = 0

  public toDTO(): IExerciseDTO {
    return serialize(this)
  }
}
