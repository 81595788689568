import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { SelectableColors } from '../../../shared/SelectableColors'
import { Workout } from '../../aggregate/Workout'
import { WorkoutsService } from '../../services/WorkoutsService'
import { ItemEditVM } from './ItemEditVM'
import { Item } from '../../aggregate/Item'
import { ItemsListVM } from './ItemsListVM'
import { SessionsCreateService } from '../../../sessions/services/SessionsCreateService'
import { SessionsService } from '../../../sessions/services/SessionsService'
import moment from 'moment'

export class WorkoutEditVM {
  private rootStore: RootStore
  private contentRef: HTMLIonContentElement

  constructor(rootStore: RootStore, exercise: Workout, isNew: boolean = false) {
    makeObservable(this)
    this.rootStore = rootStore
    this.loadWorkout(exercise, isNew)
    this.itemsListVM = new ItemsListVM(this.rootStore, this)
  }

  @observable public deleteConfirmShown: boolean = false
  @observable public dayOfMonth: number = 1
  public isNew: boolean = false
  @observable public currentTabIndex: string = '0'
  public workout: Workout
  public itemsListVM: ItemsListVM
  @observable public itemEditVM: ItemEditVM

  @action
  public loadWorkout(workout: Workout, isNew: boolean) {
    this.workout = workout.clone()
    this.isNew = Boolean(isNew)
  }

  @action
  public setName(val) {
    this.workout.setName(val)
  }

  @computed
  public get name(): string {
    return this.workout.Name
  }

  @action
  public setNotes(val) {
    this.workout.setNotes(val)
  }

  @computed
  public get notes(): string {
    return this.workout.Notes
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @computed
  public get pageTitle(): string {
    if (this.isNew) return 'New Workout'
    return 'Edit Workout'
  }

  @action
  public save() {
    if (this.itemsListVM.sortMode) {
      this.itemsListVM.exitSortMode()
      return
    }
    const svc = new WorkoutsService(this.rootStore)
    svc.save(this.workout.toDTO())
    this.rootStore.appStore.history.push('/workoutslist')
  }

  @action
  public showAddItem() {
    this.itemEditVM = new ItemEditVM(this.rootStore, this, Item.create(), true)
  }

  @action
  public addItemToWorkout(item: Item) {
    this.workout.addItem(item)
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  public delete() {
    const svc = new WorkoutsService(this.rootStore)
    svc.delete(this.workout.toDTO())
    this.rootStore.appStore.handleGoBack()
  }

  @action
  public goBack() {
    this.rootStore.appStore.handleGoBack()
  }

  public get colors() {
    return SelectableColors.colors
  }

  public setContentRef(e: HTMLIonContentElement) {
    this.contentRef = e
  }

  @action
  public setCurrentTab(val: string) {
    if (this.currentTabIndex === val) return
    this.currentTabIndex = val
    this.contentRef.scrollToTop()
  }

  @action
  public deleteItem(itemGuid: string) {
    this.workout.deleteItem(itemGuid)
  }

  @computed
  public get tabsDisabled(): boolean {
    return this.itemsListVM.sortMode
  }

  @computed
  public get saveFabShown(): boolean {
    if (this.itemsListVM.sortMode) return true
    if (this.currentTabIndex === '0') return true
    return false
  }

  @action
  public createSession() {
    const svc = new SessionsCreateService()
    const session = svc.createFromWorkout(this.workout)
    session.setSessionDate(moment())
    const svc2 = new SessionsService(this.rootStore)
    svc2.save(session.toDTO())
    this.rootStore.appStore.navigateTo('/sessionedit/' + session.SessionGuid)
  }

  public setNameInput(e: HTMLIonInputElement) {
    if (!e) return
    e.setAttribute('autocomplete', 'something-new-2' + new Date().getTime())
  }
}
