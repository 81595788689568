import { ListVM } from '@elexient/elexiapp.bits.shared'
import { action, reaction } from 'mobx'
import { RefresherEventDetail } from '@ionic/core'
import { RootStore } from '../../../stores/RootStore'
import { Session } from '../../aggregate/Session'
import { SessionGroupRowVM } from './SessionGroupRowVM'
import { SessionRowVM } from './SessionRowVM'

export class SessionsListVM extends ListVM<
  RootStore,
  Session,
  SessionGroupRowVM,
  SessionRowVM,
  undefined
> {
  constructor(rootStore: RootStore) {
    super(rootStore, SessionRowVM, SessionGroupRowVM, undefined)
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    this.atTopCheckTimeout = 500
    this.setGroups('plannerGroup', '')
    reaction(
      () => this.rootStore.appStore.listHeight,
      () => this.setHeight(this.rootStore.appStore.listHeight),
      { delay: 200 }
    )
    reaction(
      () => this.rootStore.sessionsStore.recordJustUpdated,
      () => this.reloadRows()
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  protected getRecords() {
    return this.rootStore.sessionsStore.currentBoardRecords
  }

  protected aggregateSortFunction = (a: Session, b: Session): number => {
    const aVal = a.SessionDate
    const bVal = b.SessionDate
    if (aVal > bVal) return -1
    return 0
  }

  @action
  public async refresh(e: CustomEvent<RefresherEventDetail>) {
    await this.rootStore.exercisesStore.loadData()
    if (e) (e.target as any).complete()
  }

  @action
  public addSession() {
    let url = '/sessionedit'
    this.rootStore.appStore.navigateTo(url + '/new')
  }
}
