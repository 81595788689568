import { computed, action } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { Exercise } from '../../aggregate/Exercise'
import { ExercisesListVM } from './ExercisesListVM'
import { RootStore } from '../../../stores/RootStore'

export class ExerciseRowVM extends RowVM<RootStore, ExercisesListVM, Exercise> {
  constructor(rootStore: RootStore, listVM: ExercisesListVM, exercise: Exercise, self: any) {
    super(rootStore, listVM, exercise, self)
  }

  @computed
  public get keyword(): string {
    return this.record.Name
  }

  @computed
  public get name(): string {
    return this.record.Name
  }

  @computed
  public get key(): string {
    return this.record.ExerciseGuid
  }

  @action
  public click() {
    this.rootStore.appStore.history.push('/exerciseedit/' + this.key)
  }

  @computed
  public get notes(): string {
    return this.record.Notes
  }

  @computed
  public get hasNotes(): boolean {
    return this.record.hasNotes
  }

  public get height(): number {
    if (this.isHidden) return 0
    return 44
  }
}
