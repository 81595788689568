import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Board } from '../aggregate/Board'
import { BoardEditVM } from '../view-models/BoardEditVM'
import { BoardNewModalVM } from '../view-models/BoardNewModalVM'
import { IBoardDTO } from '../dtos/IBoardDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import { BoardsMenuVM } from '../view-models/BoardsMenuVM'
import { BoardJoinModalVM } from '../view-models/BoardJoinModalVM'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import AgentV2 from '../../AgentV2'

export class BoardsStore extends DataStore<RootStore, Board, IBoardDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, Board, AgentV2, 'Boards', 'Board')
    makePersistable(this, { name: 'BoardsStore', properties: ['persistedRecords', 'currentBoardId'] }).then(
      action((st) => {
        this.isHydrated = st?.isHydrated
        this.onHydrationCompleted()
      })
    )
    makeObservable(this)
  }

  @action
  public onHydrationCompleted() {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(Board, e))))
    this.setRecords(this.persistedRecords)
    this.newVM = new BoardNewModalVM(this.rootStore)
    this.boardsMenuVM = new BoardsMenuVM(this.rootStore)
    this.joinVM = new BoardJoinModalVM(this.rootStore)
  }

  @observable.shallow public persistedRecords: Array<Board> = []
  @observable public isHydrated: boolean = false

  @observable public currentBoardId: number = 0
  @observable public editVM: BoardEditVM
  @observable public newVM: BoardNewModalVM
  @observable public joinVM: BoardJoinModalVM
  @observable public boardsMenuVM: BoardsMenuVM

  @computed
  public get currentBoardRecords() {
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  @action
  public async loadData() {
    await super.loadData()
    this.checkDefaultBoard()
  }

  @action
  private checkDefaultBoard() {
    if (this.currentBoard) return
    this.setCurrentBoard(this.persistedRecords[0].BoardId)
  }

  @action
  public setCurrentBoard(id) {
    this.currentBoardId = id
  }

  @action
  public setCurrentBoardFromNewBoardGuid(guid) {
    const brd = this.get(guid)
    if (!brd.BoardId) {
      setTimeout(() => this.setCurrentBoardFromNewBoardGuid(guid), 1000)
      return
    }
    this.currentBoardId = brd.BoardId
    this.rootStore.loadData('new board')
  }

  @computed
  public get currentBoard(): Board {
    return this.persistedRecords.find((e) => e.BoardId === this.currentBoardId)
  }

  @action
  public loadNewBoardVM() {
    this.newVM = new BoardNewModalVM(this.rootStore)
  }

  @action
  public lazyLoadEditVM(attempts: number = 0) {
    if (attempts === 10) return
    const foundBrd = this.currentBoard
    if (!foundBrd) setTimeout(() => this.lazyLoadEditVM(attempts++), 500)
    else this.editVM = new BoardEditVM(this.rootStore)
  }

  public clearData() {
    this.currentBoardId = null
    super.clearData()
  }
}
