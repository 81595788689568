import { observable, makeObservable, action } from 'mobx'
import { serialize, serializable, deserialize, object, list } from 'serializr'
import { ISetDTO } from '../dtos/ISetDTO'
import uuid from 'uuid'
import { IDTO } from '../../shared/IDTO'
import { BasedOnTypeEnum } from '../../exercises/enums/BasedOnTypeEnum'
import { WeightTypeEnum } from '../../exercises/enums/WeightTypeEnum'
import { ResistanceTypeEnum } from '../../exercises/enums/ResistanceTypeEnum'
import { Exercise } from './Exercise'
import { Scheme } from './Scheme'
import { Set } from './Set'
import { ISessionExerciseDTO } from '../dtos/ISessionExerciseDTO'

export class SessionExercise implements ISessionExerciseDTO, IDTO {
  public static createFromDTO(dto: ISessionExerciseDTO): SessionExercise {
    const it = deserialize(SessionExercise, dto)
    it.SessionExerciseGuid = uuid()
    it.Exercise = deserialize(Exercise, dto.Exercise)
    it.Scheme = deserialize(Scheme, dto.Scheme)
    return it
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public SessionExerciseGuid: string
  @serializable @observable public ExerciseGuid: string
  @serializable @observable public SchemeGuid: string
  @serializable @observable public WorkoutGuid: string
  @serializable @observable public WorkoutItemGuid: string
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public TargetSets: number = 0
  @serializable @observable public TargetWeight: number = 0
  @serializable @observable public TargetTime: number = 0
  @serializable @observable public TargetRestTime: number = 0
  @serializable @observable public ActualWeight: number = 0
  @serializable @observable public ActualTime: number = 0
  @serializable @observable public Rank: number = 0
  @serializable @observable public BasedOnTypeId: BasedOnTypeEnum = undefined
  @serializable @observable public WeightTypeId: WeightTypeEnum = undefined
  @serializable @observable public ResistanceTypeId: ResistanceTypeEnum = undefined
  @serializable @observable public IsCompleted: boolean = false
  @serializable(list(object(Set))) @observable public Sets: Set[] = []
  @serializable(object(Exercise)) @observable public Exercise: Exercise
  @serializable(object(Scheme)) @observable public Scheme: Scheme

  @action
  public toggleCompleted(setGuid: string) {
    const set = this.getSet(setGuid)
    if (!set) return
    set.toggleCompleted()
    this.checkForExerciseCompleted()
  }

  private checkForExerciseCompleted() {
    this.IsCompleted = false
    const hasUncompleted = Boolean(this.Sets.find((e) => e.IsCompleted === false))
    if (hasUncompleted) return
    this.IsCompleted = true
  }

  // @computed
  // public get RestFinishDateTime() {
  //   if (!this.IsCompleted) return undefined
  //   const dt = moment.utc(this.CompletedDateTime, ISO_8601).local()
  //   dt.add(this.TargetRestTime, 'seconds')
  //   return dt.clone().toDate()
  // }

  // public get RestSecondsRemaining() {
  //   if (!this.IsCompleted) return undefined
  //   if (!this.CompletedDateTime) return undefined
  //   const secs = moment(this.RestFinishDateTime, ISO_8601).diff(moment(), 'seconds')
  //   if (secs < 0) return 0
  //   return secs
  // }

  // public get RestTimePercentComplete() {
  //   if (!this.IsCompleted) return undefined
  //   if (!this.CompletedDateTime) return undefined
  //   if (this.RestSecondsRemaining > this.TargetRestTime) return 100
  //   return (this.RestSecondsRemaining / this.TargetRestTime) * 100
  // }

  // @action
  // public setActualValue(val: number) {
  //   if (this.ItemTypeId === ItemTypeEnum.Rest) {
  //     this.ActualTime = val
  //     console.log('rest: ' + val)
  //     return
  //   }
  //   this.ActualWeight = val
  //   console.log('weight: ' + val)
  // }

  @action
  public addSet(dto: ISetDTO) {
    const set = Set.createFromDTO(dto)
    this.Sets.push(set)
    return set
  }

  public getSet(setGuid: string) {
    return this.Sets.find((e) => e.SetGuid === setGuid)
  }

  @action
  public markAsDeleted(): void {
    this.IsDeleted = true
  }

  public toDTO(): ISetDTO {
    return serialize(this)
  }
}
