import agent from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { IWorkoutDTO } from '../dtos/IWorkoutDTO'

export class WorkoutsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: IWorkoutDTO) {
    this.rootStore.workoutsStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.WORKOUT_SAVE, dto)
  }

  public async saveOnServer(dto: IWorkoutDTO) {
    const savedDTO = await agent.Workouts.save(dto)
    this.rootStore.workoutsStore.updateFromServer(savedDTO)
  }

  public delete(dto: IWorkoutDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }
}
