import React from 'react'
import { inject, observer } from 'mobx-react'
import { ExercisesStore } from '../../store/ExercisesStore'
import { IonLabel, IonSegment, IonSegmentButton, IonBadge, IonIcon } from '@ionic/react'
import { AppStore } from 'src/stores/AppStore'
import { list, informationCircle, compass, pricetags } from 'ionicons/icons'

interface Props {
  exercisesStore?: ExercisesStore
  appStore?: AppStore
}

const TabButtons: React.FC<Props> = ({ exercisesStore, appStore }) => {
  const { editVM } = exercisesStore
  if (!editVM) return null

  return (
    <div id='TabButtons'>
      <IonSegment
        value={editVM.currentTabIndex}
        scrollable
        onIonChange={(e) => editVM.setCurrentTab(e.detail.value)}
        // color={appStore.isIos ? null : 'tertiary'}
      >
        <IonSegmentButton value='0' className='info' layout='icon-top'>
          <IonIcon icon={informationCircle} />
          <IonLabel>Info</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='1' layout='icon-top'>
          <IonIcon icon={list} />
          <IonLabel>Schemes</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='2' layout='icon-top'>
          <IonIcon icon={compass} />
          <IonLabel>Tags</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='3' layout='icon-top'>
          <IonIcon icon={compass} />
          <IonLabel>History</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </div>
  )
}

export default inject('exercisesStore', 'appStore')(observer(TabButtons))
