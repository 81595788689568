import { observable, makeObservable } from 'mobx'
import { serializable } from 'serializr'
import { ISchemeDTO } from '../../exercises/dtos/ISchemeDTO'
import { IAggregrate } from '../../shared/IAggregate'
import { IDTO } from '../../shared/IDTO'

export class Scheme implements ISchemeDTO {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public SchemeGuid: string = ''
  @serializable @observable public ExerciseGuid: string = ''
  @serializable @observable public Sets: number = undefined
  @serializable @observable public Reps: number = undefined
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public PercentageOfOneRepMax: number = undefined
  @serializable @observable public ResistanceTypeId: number = undefined
  @serializable @observable public BasedOnTypeId: number = undefined
  @serializable @observable public WeightTypeId: number = undefined
  @serializable @observable public Weight: number = undefined
  @serializable @observable public Time: number = undefined
  @serializable @observable public Rest: number = undefined

  toDTO(): IDTO {
    throw new Error('Method not implemented.')
  }
}
