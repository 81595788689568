import { IonItem, IonLabel, IonInput, IonList, IonSelect, IonSelectOption } from '@ionic/react'
import { observer } from 'mobx-react'
import { SchemeEditVM } from '../../view-models/edit/SchemeEditVM'

interface Props {
  vm: SchemeEditVM
}

const SchemeEditForm: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const handleSave = (e) => {
    e.preventDefault()
    vm.save()
  }

  const renderBasedOn = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Based On</IonLabel>
        <IonSelect interface='popover' value={vm.basedOnTypeId} onIonChange={(e: any) => vm.setBasedOn(e.target.value)}>
          {vm.basedOnTypes.map((e) => (
            <IonSelectOption key={e.id} value={e.id}>
              {e.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderWeightType = () => {
    if (!vm.weightTypeShown) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Weight Type</IonLabel>
        <IonSelect
          interface='popover'
          value={vm.weightTypeId}
          onIonChange={(e: any) => vm.setWeightType(e.target.value)}
        >
          {vm.weightTypes.map((e) => (
            <IonSelectOption key={e.id} value={e.id}>
              {e.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderResistanceType = () => {
    if (!vm.resistanceTypeShown) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Resistance</IonLabel>
        <IonSelect
          interface='popover'
          value={vm.resistanceTypeId}
          onIonChange={(e: any) => vm.setResistanceType(e.target.value)}
        >
          {vm.resistanceTypes.map((e) => (
            <IonSelectOption key={e.id} value={e.id}>
              {e.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderSets = () => {
    if (!vm.setsShown) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Sets</IonLabel>
        <IonInput type='number' autofocus value={vm.sets} onIonChange={(e: any) => vm.setSets(e.target.value)} />
      </IonItem>
    )
  }

  const renderReps = () => {
    if (!vm.repsShown) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Reps</IonLabel>
        <IonInput type='number' autofocus value={vm.reps} onIonChange={(e: any) => vm.setReps(e.target.value)} />
      </IonItem>
    )
  }

  const renderWeight = () => {
    if (!vm.weightShown) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Weight</IonLabel>
        <IonInput type='number' autofocus value={vm.weight} onIonChange={(e: any) => vm.setWeight(e.target.value)} />
      </IonItem>
    )
  }

  const renderRest = () => {
    if (!vm.restShown) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Rest</IonLabel>
        <IonInput type='number' autofocus value={vm.rest} onIonChange={(e: any) => vm.setRest(e.target.value)} />
      </IonItem>
    )
  }

  const renderTime = () => {
    if (!vm.timeShown) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Time</IonLabel>
        <IonInput type='number' autofocus value={vm.time} onIonChange={(e: any) => vm.setTime(e.target.value)} />
      </IonItem>
    )
  }

  const renderPercentageOfOneRepMax = () => {
    if (!vm.percentageOfOneRepMaxShown) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Percent of One Rep Max</IonLabel>
        <IonInput
          type='number'
          autofocus
          value={vm.percentageOfOneRepMax}
          onIonChange={(e: any) => vm.setPercentageOfOneRepMax(e.target.value)}
        />
      </IonItem>
    )
  }

  return (
    <form onSubmit={(e) => handleSave(e)}>
      <IonList>
        {renderBasedOn()}
        {renderSets()}
        {renderReps()}
        {renderResistanceType()}
        {renderWeightType()}
        {renderPercentageOfOneRepMax()}
        {renderWeight()}
        {renderTime()}
        {renderRest()}
      </IonList>
    </form>
  )
}

export default observer(SchemeEditForm)
