import React from 'react'
import { observer } from 'mobx-react'
import { SessionRowVM } from '../../view-models/list/SessionRowVM'
import { MaterialCommunityIcons } from 'react-web-vector-icons'
import { SessionStatusEnum } from '../../enums/SessionStatusEnum'

interface Props {
  row: SessionRowVM
  style: any
  index: number
}

const SessionRow: React.FC<Props> = ({ row, index, style }) => {
  const renderNotStartedIcon = () => {
    if (row.statusId !== SessionStatusEnum.Pending) return null
    return <MaterialCommunityIcons name='circle-outline' size={22} />
  }

  const renderInProgressIcon = () => {
    if (row.statusId !== SessionStatusEnum.In_Progress) return null
    return <MaterialCommunityIcons name={'circle-slice-' + row.percentCompleteOf7} size={22} />
  }

  const renderCompletedIcon = () => {
    if (row.statusId !== SessionStatusEnum.Completed) return null
    return <MaterialCommunityIcons name='circle-slice-8' size={22} />
  }

  const renderSkippedIcon = () => {
    if (row.statusId !== SessionStatusEnum.Skipped) return null
    return <MaterialCommunityIcons name='minus-circle-outline' size={22} />
  }

  return (
    <div id='SessionRow' onClick={() => row.click()} style={style} className={index % 2 === 0 ? 'odd-row' : ''}>
      <div className='cell icon'>
        {renderNotStartedIcon()}
        {renderInProgressIcon()}
        {renderCompletedIcon()}
        {renderSkippedIcon()}
      </div>
      <div className='cell'>
        <div>{row.name}</div>
        <div className={row.hasNotes ? 'notes' : 'notes no-notes'}>{row.notes}</div>
      </div>
      <div className='grow'></div>
      <div className='cell'>
        <div>{row.sessionDate}</div>
      </div>
    </div>
  )
}

export default observer(SessionRow)
