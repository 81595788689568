import { ITheme } from './ITheme'

export const SYSTEM_THEMES: Array<ITheme> = [
  {
    name: 'default',
    primaryColor: '#2a9d8f',
    secondaryColor: '#50e3c2',
    tertiaryColor: '#942cbe',
    mediumColor: '#e4fff8',
    lightColor: '#ffffff',
    boardColor: '#b0e8d5',
    darkColor: '#222428',
  },
  {
    name: 'dark',
    primaryColor: '#000000',
    secondaryColor: '#272929',
    tertiaryColor: '#097785',
    mediumColor: '#333333',
    lightColor: '#1f1e1f',
    boardColor: '#464546',
    darkColor: '#071108',
  },
]
