import React from 'react'
import { inject, observer } from 'mobx-react'
import { ExercisesStore } from '../../store/ExercisesStore'
import { AppStore } from '../../../stores/AppStore'
import {
  IonItem,
  IonLabel,
  IonInput,
  IonCol,
  IonGrid,
  IonRow,
  IonFabButton,
  IonFab,
  IonIcon,
  IonList,
} from '@ionic/react'
import { checkmarkSharp } from 'ionicons/icons'

interface Props {
  exercisesStore?: ExercisesStore
  appStore?: AppStore
}

const ExercieEditForm: React.FC<Props> = ({ exercisesStore, appStore }) => {
  const { editVM } = exercisesStore
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null
  if (editVM.currentTabIndex !== '0') return null

  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput
          type='text'
          value={editVM.name}
          onIonChange={(e: any) => editVM.setName(e.target.value)}
          ref={e => editVM.setNameInput(e)}
        />
      </IonItem>
    )
  }

  const renderOneRepMax = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>One Rep Max</IonLabel>
        <IonInput
          type='number'
          value={editVM.oneRepMax}
          onIonChange={(e: any) => editVM.setOneRepMax(e.target.value)}
        />
      </IonItem>
    )
  }

  const renderNotes = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Notes</IonLabel>
        <IonInput
          type='text'
          value={editVM.notes}
          onIonChange={(e: any) => editVM.setNotes(e.target.value)}
          autocomplete='off'
        />
      </IonItem>
    )
  }

  return (
    <form
      id='InfoForm'
      onSubmit={(e) => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonList>
        {renderName()}
        {renderOneRepMax()}
        {renderNotes()}
      </IonList>
    </form>
  )
}

export default inject('exercisesStore', 'appStore')(observer(ExercieEditForm))
