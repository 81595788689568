import { computed, action, observable } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { RootStore } from '../../../stores/RootStore'
import { ItemsListVM } from './ItemsListVM'
import { Item } from '../../aggregate/Item'

export class ItemRowVM extends RowVM<RootStore, ItemsListVM, Item> {
  constructor(rootStore: RootStore, listVM: ItemsListVM, item: Item, self: any) {
    super(rootStore, listVM, item, self)
  }

  public get height(): number {
    if (this.isHidden) return 0
    return 50
  }

  @computed
  public get keyword(): string {
    return ''
  }

  public get key(): string {
    return this.record.ItemGuid
  }

  public get displayName(): string {
    return this.record.Exercise.Name
  }

  public get schemeInfo(): string {
    let info = String(this.record.Scheme.Sets)
    info = info + 'x' + this.record.Scheme.Reps
    info = info + '@' + this.record.Scheme.Weight
    return info
  }

  public get rest(): string {
    return String(this.record.Scheme.Rest) + 's'
  }

  @computed
  public get rank() {
    return this.record.Rank
  }

  @action
  public delete() {
    this.rootStore.workoutsStore.editVM.deleteItem(this.record.ItemGuid)
  }

  @action
  public click() {
    this.rootStore.workoutsStore.editVM.itemsListVM.enterSortMode()
    // alert('nope')
  }
}
