import { observable, action, makeObservable, computed } from 'mobx'
import { serialize, deserialize, serializable } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { ISchemeDTO } from '../dtos/ISchemeDTO'
import uuid from 'uuid'

export class Scheme implements ISchemeDTO, IAggregrate {
  public static create(): Scheme {
    const scheme = new Scheme()
    scheme.SchemeGuid = uuid()
    return scheme
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public SchemeGuid: string = ''
  // @serializable @observable public ExerciseGuid: string = ''
  @serializable @observable public Sets: number = undefined
  @serializable @observable public Reps: number = undefined
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public PercentageOfOneRepMax: number = undefined
  @serializable @observable public ResistanceTypeId: number = undefined
  @serializable @observable public BasedOnTypeId: number = undefined
  @serializable @observable public WeightTypeId: number = undefined
  @serializable @observable public Weight: number = undefined
  @serializable @observable public Time: number = undefined
  @serializable @observable public Rest: number = undefined

  @action
  public setSets(val: number) {
    this.Sets = Number(val)
  }

  @action
  public setReps(val: number) {
    this.Reps = Number(val)
  }

  @action
  public setTime(val: number) {
    this.Time = Number(val)
  }

  @action
  public setBasedOn(val: number) {
    this.BasedOnTypeId = val
  }

  @action
  public setWeightType(val: number) {
    this.WeightTypeId = val
  }

  @action
  public setWeight(val: number) {
    this.Weight = Number(val)
  }

  @action
  public setRest(val: number) {
    this.Rest = Number(val)
  }

  @action
  public setResistanceType(val: number) {
    this.ResistanceTypeId = val
  }

  @action
  public setPercentageOfOneRepMax(val: number) {
    this.PercentageOfOneRepMax = Number(val)
  }

  public toDTO(): ISchemeDTO {
    return serialize(this)
  }
}
