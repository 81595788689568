import * as Sentry from '@sentry/browser'
import { AppStore } from './AppStore'
import { AuthStore } from '../login/store/AuthStore'
import { ActionsStore } from '../actions/store/ActionsStore'
import { BoardsStore } from '../boards/store/BoardsStore'
import { BoardInvitationsStore } from '../board-invitations/store/BoardInvitationsStore'
import { UserStore } from '../user/store/UserStore'
import { delay } from '../shared/delay'
import { RootStore as ElexiAppRootStore, IStore } from '@elexient/elexiapp.bits.shared'
import { ExercisesStore } from '../exercises/store/ExercisesStore'
import { WorkoutsStore } from '../workouts/store/WorkoutsStore'
import { SessionsStore } from '../sessions/store/SessionsStore'

export class RootStore extends ElexiAppRootStore {
  private loading: boolean = false
  private delayMS: number = 0

  constructor() {
    super()
    this.appStore = this.addStore<AppStore>(AppStore)
    this.authStore = this.addStore<AuthStore>(AuthStore)
    this.boardsStore = this.addStore<BoardsStore>(BoardsStore)
    this.actionsStore = this.addStore<ActionsStore>(ActionsStore)
    this.userStore = this.addStore<UserStore>(UserStore)
    this.boardInvitationsStore = this.addStore<BoardInvitationsStore>(BoardInvitationsStore)
    this.exercisesStore = this.addStore<ExercisesStore>(ExercisesStore)
    this.workoutsStore = this.addStore<WorkoutsStore>(WorkoutsStore)
    this.sessionsStore = this.addStore<SessionsStore>(SessionsStore)
  }

  public appStore: AppStore
  public authStore: AuthStore
  public actionsStore: ActionsStore
  public exercisesStore: ExercisesStore
  public boardsStore: BoardsStore
  public userStore: UserStore
  public boardInvitationsStore: BoardInvitationsStore
  public workoutsStore: WorkoutsStore
  public sessionsStore: SessionsStore

  public loadData(reason: string, attempts: number = 1) {
    if (this.loading && reason !== 'user logged in') {
      console.log('loading already')
      return
    }
    if (process.env.NODE_ENV !== 'test') console.log('loading data: ' + reason + ' attempt: ' + attempts)
    attempts++
    if (attempts === 7) return delay(1)
    if (!this.appStore.isLoggedIn) {
      return delay(2000).then(() => this.loadData(reason, attempts))
    }
    if (process.env.NODE_ENV === 'test') {
      const proms = [this.boardsStore.loadData()]
      return Promise.all(proms)
    }
    const proms = [
      delay(this.getMoreTime(1000)).then(() => {
        const name = 'boards'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.boardsStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'user'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.userStore.loadUser().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'boardinvitations'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.boardInvitationsStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'exercises'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.exercisesStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'workouts'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.workoutsStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'sessions'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.sessionsStore.loadData().then(() => console.timeEnd(name))
      }),
    ]
    console.log('loading all data')
    this.loading = true
    return delay(1000)
      .then(() => Promise.all(proms).then(() => (this.loading = false)))
      .catch((e) => {
        Sentry.captureException(e)
        console.log(e)
      })
  }

  private getMoreTime(ms) {
    this.delayMS = this.delayMS + ms
    return this.delayMS
  }
}
