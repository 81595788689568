import React from 'react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { RootStore } from '../../../stores/RootStore'
import { match } from 'react-router'
import { SessionsStore } from '../../store/SessionsStore'
import SessionEditArea from './SessionEditArea'

interface Props {
  sessionsStore: SessionsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('sessionsStore')
@inject('appStore')
@observer
export default class SessionEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute(this.props.match.url)
    this.props.sessionsStore.lazyLoadEditVM(this.props.match.params['id'])
  }

  componentWillUnmount() {
    this.props.sessionsStore.lazyLoadEditVM('empty')
  }

  render() {
    return <SessionEditArea />
  }
}
