import { observable, action, computed, makeObservable, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Session } from '../aggregate/Session'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import { makePersistable } from 'mobx-persist-store'
import AgentV2 from '../../AgentV2'
import { deserialize } from 'serializr'
import { ISessionDTO } from '../dtos/ISessionDTO'
import { SessionEditVM } from '../view-models/edit/SessionEditVM'
import { SessionsListVM } from '../view-models/list/SessionsListVM'

export class SessionsStore extends DataStore<RootStore, Session, ISessionDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, Session, AgentV2, 'Sessions', 'Session')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'SessionsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydrated?: boolean) {
    this.persistedRecords = observable.array(
      this.persistedRecords.map((e) => makeObservable(deserialize(Session, e)))
    )
    super.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydrated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.listVM = new SessionsListVM(this.rootStore as RootStore)
  }

  @observable.shallow public persistedRecords: Array<Session> = []
  @observable public editVM: SessionEditVM = null
  @observable public listVM: SessionsListVM = null
  @observable public isHydrated: boolean = false

  public onLoadDataCompleted(): void {
    if (!this.listVM) return
    this.listVM.reloadRows()
  }

  @action
  public lazyLoadEditVM(sessionGuid: string, attempts: number = 0) {
    if (attempts === 10) return
    if (!this.rootStore.boardsStore.isHydrated) {
      setTimeout(() => this.lazyLoadEditVM(sessionGuid, attempts++), 500)
      return
    }
    if (!this.rootStore.exercisesStore.isHydrated) {
      setTimeout(() => this.lazyLoadEditVM(sessionGuid, attempts++), 500)
      return
    }
    if (sessionGuid === 'new') {
      this.editVM = new SessionEditVM(
        this.rootStore,
        Session.create(this.rootStore.boardsStore.currentBoardId)
      )
    } else if (sessionGuid === 'empty') {
      this.editVM = null
    } else {
      const session = this.get(sessionGuid)
      if (!session) {
        setTimeout(() => this.lazyLoadEditVM(sessionGuid, attempts++), 500)
        return
      }
      this.editVM = new SessionEditVM(this.rootStore, session.clone())
    }
  }

  @computed
  public get currentBoardRecords() {
    if (!this.isHydrated) return []
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  // public onAfterUpdate(ses: Session): void {
  //   this.refreshEditVM(ses)
  // }

  // private refreshEditVM(ses: Session) {
  //   if (!this.editVM) return
  //   if (this.editVM.session.SessionGuid !== ses.SessionGuid) return
  //   // this.editVM.loadSession(ses, false)
  // }
}
