import { observable, action, reaction, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Action } from '../aggregate/Action'
import { ActionsService } from '../service/ActionsService'
import { IActionsStore } from '@elexient/elexiapp.bits.shared/stores/IActionsStore'
import { persist } from '../../shared/persist'

export class ActionsStore implements IActionsStore {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.actionsSvc = new ActionsService(rootStore, this)
  }

  @persist('list', Action) @observable public actions: Array<Action> = []
  public actionsSvc: ActionsService = null

  @computed
  public get hasActions() {
    return this.actions.length !== 0
  }

  public hasPendingActions(itemType: string) {
    return this.actions.find(e => e.itemType === itemType.toLowerCase())
  }

  @computed
  public get hasItemActions() {
    return this.actions.filter(e => e.typeName.toLowerCase().includes('item_save')).length !== 0
  }

  @computed
  public get hasCategoryActions() {
    return this.actions.filter(e => e.typeName.toLowerCase().includes('category_save')).length !== 0
  }

  @action
  public addAction(type: number, dto) {
    this.actions.push(Action.create(type, dto))
    this.actionsSvc.processActions()
  }

  @action
  public clearData() {
    this.actions = []
  }
}
