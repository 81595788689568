import { RootStore } from '../../../stores/RootStore'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { action, makeObservable, observable, reaction } from 'mobx'
import { SchemeRowVM } from './SchemeRowVM'
import { SchemeGroupRowVM } from './SchemeGroupRowVM'
import { ItemEditVM } from './ItemEditVM'
import { Scheme } from '../../aggregate/Scheme'
import { deserialize, serialize } from 'serializr'

export class SchemesListVM extends ListVM<RootStore, Scheme, SchemeGroupRowVM, SchemeRowVM, undefined> {
  private editVM: ItemEditVM

  constructor(rootStore: RootStore, editVM: ItemEditVM) {
    super(rootStore, SchemeRowVM, SchemeGroupRowVM, undefined)
    makeObservable(this)
    this.editVM = editVM
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    this.atTopCheckTimeout = 500
    this.setGroups('exerciseName', '')
    this.addReaction(
      reaction(
        () => this.rootStore.appStore.listHeight,
        () => this.setHeight(this.rootStore.appStore.listHeight),
        { delay: 200 }
      )
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  protected getRecords() {
    const items = []
    this.rootStore.exercisesStore.currentBoardRecords.forEach((e) => {
      const schemes = e.Schemes.map((ee) => {
        const dto = ee.toDTO() as any
        dto.ExerciseGuid = e.ExerciseGuid
        return deserialize(Scheme, dto)
      })
      items.push(...schemes)
    })
    return items
  }

  protected sortFunction = (a: Scheme, b: Scheme): number => {
    const aVal = a.Sets
    const bVal = b.Sets
    if (aVal > bVal) return -1
    return 0
  }

  @action
  public addScheme() {
    // this.editVM
  }
}
