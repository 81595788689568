import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAlert,
  IonFab,
  IonFabButton,
  IonList,
  IonPopover,
  IonItem,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { close, trashOutline, checkmarkSharp, caretForward, ellipsisVertical, pencilOutline } from 'ionicons/icons'
import { AppStore } from '../../../stores/AppStore'
import SessionEditForm from './SessionEditForm'
import BackIcon from '../../../shared/BackIcon'
import { SessionsStore } from '../../store/SessionsStore'
import { EvilIcons, MaterialCommunityIcons, Entypo } from 'react-web-vector-icons'
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { list } from 'serializr'
import SetsList from './SetsList'
import SetsSortableList from './SetsSortableList'

interface Props {
  sessionsStore?: SessionsStore
  appStore?: AppStore
}

const SessionEditArea: React.FC<Props> = ({ sessionsStore, appStore }) => {
  const { editVM } = sessionsStore
  if (!editVM) return null
  const listVM = editVM.setsListVM

  const renderAppHeader = () => {
    if (!appStore.showAppHeader) return
    return (
      <IonHeader>
        <IonToolbar />
      </IonHeader>
    )
  }

  const renderPageHeader = () => {
    return (
      <IonHeader id='ExerciseEditPage_Header'>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => editVM.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>{editVM.pageTitle}</IonTitle>
          <IonButtons slot='end'>
            {renderExpandAll()}
            {renderCollapseAll()}
            <IonButton onClick={(e) => editVM.openMenu(e)}>
              <IonIcon icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    )
  }

  const renderExpandAll = () => {
    if (!listVM.startCollapsed) return
    return (
      <IonButton onClick={(e) => listVM.expandCollapseAll()}>
        <FontAwesomeIcon icon={faAngleDoubleDown} />
      </IonButton>
    )
  }

  const renderCollapseAll = () => {
    if (listVM.startCollapsed) return
    return (
      <IonButton onClick={(e) => listVM.expandCollapseAll()}>
        <FontAwesomeIcon icon={faAngleDoubleUp} />
      </IonButton>
    )
  }

  const renderDeleteConfirm = () => {
    if (!editVM.deleteConfirmShown) return
    return (
      <IonAlert
        isOpen={true}
        onDidDismiss={() => editVM.hideDeleteConfirm()}
        header={'Delete Item'}
        message={'Are you sure you wish to delete ' + editVM.name}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => editVM.hideDeleteConfirm(),
          },
          {
            text: 'Yes',
            handler: () => editVM.delete(),
          },
        ]}
      />
    )
  }

  const renderSaveFab = () => {
    if (!editVM.saveFabShown) return null
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          color='tertiary'
          disabled={!editVM.isValid}
          onClick={() => editVM.save(true)}
          style={{
            marginTop: '-60px',
            marginLeft: '-60px',
          }}
        >
          <IonIcon icon={checkmarkSharp} />
        </IonFabButton>
      </IonFab>
    )
  }

  const renderNextFab = () => {
    if (!editVM.nextFabShown) return null
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          color='tertiary'
          disabled={!editVM.isValid}
          onClick={() => editVM.next()}
          style={{
            marginTop: '-60px',
            marginLeft: '-60px',
          }}
        >
          <IonIcon icon={caretForward} />
        </IonFabButton>
      </IonFab>
    )
  }

  const renderMenu = () => {
    // if (!editVM.isMenuOpen) return null
    return (
      <IonPopover
        id='SessionEditMenu'
        isOpen={editVM.isMenuOpen}
        event={editVM.menuEvent}
        onDidDismiss={() => editVM.closeMenu()}
      >
        <IonList>
          <IonItem onClick={() => editVM.enterEditMode()}>
            <IonIcon icon={pencilOutline} color='white' />
            &nbsp; Modify Exercises
          </IonItem>
          <IonItem onClick={() => editVM.showDeleteConfirm()}>
            <IonIcon icon={trashOutline} color='white' />
            &nbsp; Delete Session
          </IonItem>
        </IonList>
      </IonPopover>
    )
  }

  return (
    <>
      {renderAppHeader()}
      {renderPageHeader()}
      <IonContent
        id='SessionEditPage_Content'
        ref={(e) => {
          editVM.setContentRef(e)
          appStore.applyScrollStyles(e)
        }}
      >
        <SetsList />
        <SetsSortableList />
      </IonContent>
      {renderDeleteConfirm()}
      {renderSaveFab()}
      {renderNextFab()}
      {renderMenu()}
    </>
  )
}

export default inject('sessionsStore', 'appStore')(observer(SessionEditArea))
