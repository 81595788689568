import React from 'react'
import {
  IonContent,
  IonItem,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabButton,
  IonFabList,
} from '@ionic/react'
import { observer, inject, Observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { ExercisesStore } from '../../../exercises/store/ExercisesStore'
import { Exercise } from '../../../exercises/aggregate/Exercise'
import {
  trash,
  add,
  arrowBack,
  trashOutline,
  cardOutline,
  enterOutline,
  exitOutline,
  repeatOutline,
  checkmarkSharp,
  checkmarkDoneCircleOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import ExercisesList from './ExercisesList'
import BackIcon from '../../../shared/BackIcon'

interface Props {
  exercisesStore: ExercisesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

interface State {
  isNewModalOpen: boolean
  isEditModalOpen: boolean
  editExerciseGuid: string
  isDeleteConfirmShown: boolean
  deleteExerciseGuid: string
  deleteExerciseName: string
}

@inject('exercisesStore')
@inject('appStore')
export default class ExercisesListPage extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/exerciseslist')
  }

  handleAddButtonClick() {
    this.setState({ isNewModalOpen: true })
  }

  handleEditClick(exerciseGuid) {
    this.setState({
      isEditModalOpen: true,
      editExerciseGuid: exerciseGuid,
    })
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  openListItem(listItem) {
    this.props.history.push('/maintabs/listitemedit/' + listItem.ListItemGuid)
  }

  addExercise() {
    const listVM = this.props.exercisesStore.listVM
    if (!listVM) return
    listVM.addExercise()
  }

  renderNoItemsMessage() {
    if (this.props.exercisesStore.currentBoardRecords.length > 0) return null
    return (
      <div className='no-items'>
        You have no exercises set up!
        <br />
        <br />
        <IonButton slot='icon-only' type='button' onClick={() => this.props.appStore.navigateTo('/exerciseedit/new')}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    )
  }

  renderFab() {
    const listVM = this.props.exercisesStore.listVM
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton color='tertiary' className='exercise-fab-button' onClick={() => listVM.addExercise()}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
    )
  }

  renderRefresher() {
    const listVM = this.props.exercisesStore.listVM
    if (!listVM) return
    return (
      <Observer>
        {() => {
          return (
            <IonRefresher slot='fixed' onIonRefresh={(e) => listVM.refresh(e)} disabled={!listVM.atTop}>
              <IonRefresherContent />
            </IonRefresher>
          )
        }}
      </Observer>
    )
  }

  render() {
    return (
      <Observer>
        {() => {
          return (
            <>
              {this.props.appStore.showAppHeader && (
                <IonHeader>
                  <IonToolbar />
                </IonHeader>
              )}
              <IonHeader id='ExercisesListPage_Header'>
                <IonToolbar color='secondary'>
                  <IonTitle>Exercises</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent
                id='ExercisesListPage_Content'
                ref={(e) => this.props.appStore.applyScrollStyles(e)}
                style={{ height: this.props.appStore.listHeightPx }}
              >
                {this.renderNoItemsMessage()}
                {this.renderRefresher()}
                <ExercisesList />
                {this.renderFab()}
              </IonContent>
            </>
          )
        }}
      </Observer>
    )
  }
}
