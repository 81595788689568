import { action, computed, makeObservable, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { Item } from '../../aggregate/Item'
import { SchemesListVM } from './SchemesListVM'
import { WorkoutEditVM } from './WorkoutEditVM'

export class ItemEditVM {

  private rootStore: RootStore
  private item: Item
  private isNew: boolean
  private parentVM: WorkoutEditVM

  constructor(rootStore: RootStore, parentVM: WorkoutEditVM, item: Item, isNew: boolean = false) {
    makeObservable(this)
    this.rootStore = rootStore
    this.item = item
    this.parentVM = parentVM
    this.isNew = isNew
    this.schemesListVM = new SchemesListVM(this.rootStore, this)
  }

  @observable public shown: boolean = true
  public schemesListVM: SchemesListVM = undefined

  @action
  public show() {
    this.shown = true
  }

  @action
  public hide() {
    this.shown = false
  }

  @action
  public save() {
    this.hide()
  }

  @computed
  public get isValid(): boolean {
    var isValid = true
    return isValid
  }

  @action
  public setExercise(exerciseGuid: string, schemeGuid: string) {
    const ex = this.rootStore.exercisesStore.get(exerciseGuid).toDTO()
    const sch = ex.Schemes.find(e => e.SchemeGuid === schemeGuid)
    this.item.setExercise(ex, sch)
    this.hide()
    this.parentVM.addItemToWorkout(this.item)
    this.parentVM.itemsListVM.reloadRows()
  }
}
