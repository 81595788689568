import React from 'react'
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../stores/AppStore'

interface Props {
  appStore: AppStore
}

@inject('appStore')
@observer
export default class DashboardPage extends React.Component<Props, any> {
  componentDidMount() {
    this.props.appStore.setCurrentRoute('/')
  }

  handleRefresh(event) {
    setTimeout(() => {
      if (event) event.target.complete()
    }, 200)
  }

  renderRefresher() {
    return (
      <IonRefresher slot='fixed' onIonRefresh={(e) => this.handleRefresh(e)}>
        <IonRefresherContent />
      </IonRefresher>
    )
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar />
        </IonHeader>

        <IonHeader id='DashboardPage_Header'>
          <IonToolbar color='secondary'>
            <IonTitle>Dashboard</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent id='DashboardPage_Content' style={{ height: this.props.appStore.listHeightPx }} color='medium'>
          {this.renderRefresher()}
        </IonContent>
      </>
    )
  }
}
