import { observable, makeObservable } from 'mobx'
import { serializable, serialize } from 'serializr'
import { IDTO } from '../../shared/IDTO'
import { ISchemeDTO } from '../dtos/ISchemeDTO'

export class Scheme implements ISchemeDTO, IDTO {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public SchemeGuid: string = ''
  @serializable @observable public ExerciseGuid: string = ''
  @serializable @observable public Sets: number = undefined
  @serializable @observable public Reps: number = undefined
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public PercentageOfOneRepMax: number = undefined
  @serializable @observable public ResistanceTypeId: number = undefined
  @serializable @observable public BasedOnTypeId: number = undefined
  @serializable @observable public WeightTypeId: number = undefined
  @serializable @observable public Weight: number = undefined
  @serializable @observable public Time: number = undefined
  @serializable @observable public Rest: number = undefined
  

  public toDTO(): ISchemeDTO {
    return serialize(this)
  }
}
