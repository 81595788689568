import agent from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { IExerciseDTO } from '../dtos/IExerciseDTO'

export class ExercisesService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: IExerciseDTO) {
    this.rootStore.exercisesStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.EXERCISE_SAVE, dto)
  }

  public async saveOnServer(dto: IExerciseDTO) {
    const savedDTO = await agent.Exercises.save(dto)
    this.rootStore.exercisesStore.updateFromServer(savedDTO)
  }

  public delete(dto: IExerciseDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }
}
