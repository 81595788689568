import { action, computed, makeObservable, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { Scheme } from '../../aggregate/Scheme'
import { BasedOnTypeEnum } from '../../enums/BasedOnTypeEnum'
import { BasedOnTypeEnumName } from '../../enums/BasedOnTypeEnumName'
import { ResistanceTypeEnum } from '../../enums/ResistanceTypeEnum'
import { ResistanceTypeEnumName } from '../../enums/ResistanceTypeEnumName'
import { WeightTypeEnum } from '../../enums/WeightTypeEnum'
import { WeightTypeEnumName } from '../../enums/WeightTypeEnumName'
import { ExerciseEditVM } from './ExerciseEditVM'

export class SchemeEditVM {
  private rootStore: RootStore
  private scheme: Scheme
  private isNew: boolean
  private parentVM: ExerciseEditVM

  constructor(rootStore: RootStore, parentVM: ExerciseEditVM, scheme: Scheme, isNew: boolean = false) {
    makeObservable(this)
    this.rootStore = rootStore
    this.scheme = scheme
    this.parentVM = parentVM
    this.isNew = isNew
  }

  @observable public shown: boolean = true

  @action
  public show() {
    this.shown = true
  }

  @action
  public hide() {
    this.shown = false
  }

  @computed
  public get sets(): number {
    return this.scheme.Sets
  }

  @action
  public setSets(val) {
    this.scheme.setSets(val)
  }

  @computed
  public get reps(): number {
    return this.scheme.Reps
  }

  @action
  public setReps(val) {
    this.scheme.setReps(val)
  }

  @action
  public save() {
    this.hide()
    if (this.isNew) {
      this.parentVM.addSavedScheme(this.scheme)
    }
  }

  @computed
  public get isValid(): boolean {
    var isValid = true
    if (!Boolean(this.sets)) isValid = false
    if (!Boolean(this.reps)) isValid = false
    return isValid
  }

  public get basedOnTypes() {
    return BasedOnTypeEnumName.list
  }

  @computed
  public get basedOnTypeId() {
    return this.scheme.BasedOnTypeId
  }

  @action
  public setBasedOn(val: number) {
    this.scheme.setBasedOn(val)
  }

  public get weightTypes() {
    return WeightTypeEnumName.list
  }

  @computed
  public get weightTypeId() {
    return this.scheme.WeightTypeId
  }

  @action
  public setWeightType(val: number) {
    this.scheme.setWeightType(val)
  }

  @computed
  public get weight() {
    return this.scheme.Weight
  }

  @action
  public setWeight(val: number) {
    this.scheme.setWeight(val)
  }

  public get resistanceTypes() {
    return ResistanceTypeEnumName.list
  }

  @computed
  public get resistanceTypeId() {
    return this.scheme.ResistanceTypeId
  }

  @action
  public setResistanceType(val: number) {
    this.scheme.setResistanceType(val)
  }

  @computed
  public get rest() {
    return this.scheme.Rest
  }

  @action
  public setRest(val: number) {
    this.scheme.setRest(val)
  }

  @computed
  public get time() {
    return this.scheme.Time
  }

  @action
  public setTime(val: number) {
    this.scheme.setTime(val)
  }

  @computed
  public get percentageOfOneRepMax() {
    return this.scheme.PercentageOfOneRepMax
  }

  @action
  public setPercentageOfOneRepMax(val: number) {
    this.scheme.setPercentageOfOneRepMax(val)
  }

  @computed
  public get setsShown(): boolean {
    if (this.basedOnTypeId != BasedOnTypeEnum.Reps) return false
    return true
  }

  @computed
  public get restShown(): boolean {
    return true
  }

  @computed
  public get repsShown(): boolean {
    if (this.basedOnTypeId != BasedOnTypeEnum.Reps) return false
    return true
  }

  @computed
  public get weightShown(): boolean {
    if (this.basedOnTypeId != BasedOnTypeEnum.Reps) return false
    if (this.weightTypeId != WeightTypeEnum.Fixed) return false
    return true
  }

  @computed
  public get weightTypeShown(): boolean {
    if (this.basedOnTypeId != BasedOnTypeEnum.Reps) return false
    return true
  }

  @computed
  public get resistanceTypeShown(): boolean {
    return true
  }

  @computed
  public get timeShown(): boolean {
    if (this.basedOnTypeId != BasedOnTypeEnum.Time) return false
    return true
  }

  @computed
  public get percentageOfOneRepMaxShown(): boolean {
    if (this.basedOnTypeId != BasedOnTypeEnum.Reps) return false
    if (this.weightTypeId != WeightTypeEnum.Percentage_of_One_Rep_Max) return false
    return true
  }
}
