import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { IonItem, IonLabel, IonInput, IonList, IonButton } from '@ionic/react'
import { WorkoutsStore } from '../../store/WorkoutsStore'

interface Props {
  workoutsStore?: WorkoutsStore
  appStore?: AppStore
}

const WorkoutEditForm: React.FC<Props> = ({ workoutsStore, appStore }) => {
  const { editVM } = workoutsStore
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null
  if (editVM.currentTabIndex !== '0') return null

  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput type='text' value={editVM.name} onIonChange={(e: any) => editVM.setName(e.target.value)} ref={e => editVM.setNameInput(e)} />
      </IonItem>
    )
  }

  const renderNotes = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Notes</IonLabel>
        <IonInput type='text' value={editVM.notes} onIonChange={(e: any) => editVM.setNotes(e.target.value)} />
      </IonItem>
    )
  }

  const renderStart = () => {
    return (
      <IonButton type='button' size='small' color='tertiary' onClick={() => editVM.createSession()}>
        Plan Session
      </IonButton>
    )
  }

  return (
    <form
      id='InfoForm'
      onSubmit={(e) => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonList>
        {renderName()}
        {renderNotes()}
        {renderStart()}
      </IonList>
    </form>
  )
}

export default inject('workoutsStore', 'appStore')(observer(WorkoutEditForm))
