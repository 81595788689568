import { observable } from 'mobx'
import { ActionTypeEnum } from '../enum/ActionTypeEnum'
import { persist } from '../../shared/persist'

export class Action {
  static create(type: number, dto: any): Action {
    const action = new Action()
    action.type = type
    action.dtoString = JSON.stringify(dto)
    return action
  }

  @persist public type: number = 0
  @persist public dtoString: string = ''
  public attempts: number = 0

  public get dto(): any {
    return JSON.parse(this.dtoString)
  }

  public get typeName(): string {
    if (this.type === ActionTypeEnum.BOARD_SAVE) return 'BOARD_SAVE'
    if (this.type === ActionTypeEnum.BOARDINVITATION_SAVE) return 'BOARDINVITATION_SAVE'
    if (this.type === ActionTypeEnum.EXERCISE_SAVE) return 'EXERCISE_SAVE'
    if (this.type === ActionTypeEnum.WORKOUT_SAVE) return 'WORKOUT_SAVE'
    if (this.type === ActionTypeEnum.SESSION_SAVE) return 'SESSION_SAVE'
    return '(NOT SET)'
  }

  public get itemType(): string {
    return this.typeName.split('_')[0].toLowerCase()
  }
}
