import { Workout } from '../../workouts/aggregate/Workout'
import { Session } from '../aggregate/Session'
import { ISessionExerciseDTO } from '../dtos/ISessionExerciseDTO'
import { ISetDTO } from '../dtos/ISetDTO'

export class SessionsCreateService {
  public createFromWorkout(workout: Workout) {
    const session = Session.create(workout.BoardId)
    session.setName(workout.Name)
    workout.Items.forEach((e) => {
      const dto = {} as ISessionExerciseDTO
      dto.TargetSets = e.Scheme.Sets
      dto.TargetWeight = e.Scheme.Weight
      dto.ActualWeight = e.Scheme.Weight
      dto.BasedOnTypeId = e.Scheme.BasedOnTypeId
      dto.WeightTypeId = e.Scheme.WeightTypeId
      dto.ExerciseGuid = e.ExerciseGuid
      dto.SchemeGuid = e.SchemeGuid
      dto.WorkoutGuid = workout.WorkoutGuid
      dto.WorkoutItemGuid = e.ItemGuid
      dto.Rank = (e.Rank + 1) * 100
      dto.Exercise = e.Exercise.toDTO()
      dto.Scheme = e.Scheme.toDTO()
      dto.TargetRestTime = e.Scheme.Rest
      const ex = session.addExercise(dto)
      for (let setNo = 0; setNo < e.Scheme.Sets; setNo++) {
        const setDto = {} as ISetDTO
        setDto.SetNumber = setNo + 1
        setDto.TargetReps = dto.Scheme.Reps
        setDto.ActualReps = dto.Scheme.Reps
        setDto.ActualTime = dto.TargetTime
        setDto.ActualWeight = dto.TargetWeight
        setDto.TargetRestTime = dto.TargetRestTime
        setDto.SessionExerciseGuid = ex.SessionExerciseGuid
        ex.addSet(setDto)
      }
    })
    return session
  }
}
