import React from 'react'
import { inject, observer } from 'mobx-react'
import { IonIcon, IonItem, IonItemOption, IonText } from '@ionic/react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { pencilOutline, reorderTwo, trashOutline } from 'ionicons/icons'
import { SetRowVM } from '../../view-models/edit/SetRowVM'
import { SessionsStore } from '../../store/SessionsStore'

interface Props {
  sessionsStore?: SessionsStore
}

const SetsSortableList: React.FC<Props> = ({ sessionsStore }) => {
  const editVM = sessionsStore.editVM
  if (!editVM) return null
  const listVM = editVM.setsListVM
  if (!listVM) return null
  if (!editVM.editMode) return null

  const renderRow = (row: SetRowVM, idx) => {
    return (
      <IonItem lines='none' className='normal-row sort-mode' onClick={() => row.click()} key={row.key}>
        <div className='sort-handle'>
          <IonIcon icon={reorderTwo} size='small' />
        </div>
        <div className='main-cell'>
          <div className='exercise-name'>{row.exerciseDisplayName}</div>
          <div className='exercise-description'>{row.exerciseDescription}</div>
          <div className='rest'>Rest {row.targetRestTime}s</div>
        </div>
        <div className='right-cell'>
        <IonIcon
            onMouseDown={(e) => {
              e.stopPropagation()
              alert('not implemented')
              // editVM.showEditExercise(row.exerciseGuid)
            }}
            icon={pencilOutline}
          />
          &nbsp;
          <IonIcon
            onMouseDown={(e) => {
              e.stopPropagation()
              alert('not implemented')
              // editVM.deleteExercise(row.exerciseGuid)
            }}
            icon={trashOutline}
          />
        </div>
      </IonItem>
    )
  }

  const SortableItem = SortableElement(({ value, index }) => {
    return <div>{renderRow(value, index)}</div>
  })

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    )
  })

  return (
    <div className='list'>
      <SortableList
        items={listVM.allRows
          .filter((e: SetRowVM) => e.rowType === 'normal' && e.setNumber === 1)
          .sort((a, b) => ((a as SetRowVM).rank < (b as SetRowVM).rank ? -1 : 0))}
        onSortEnd={({ oldIndex, newIndex }) => listVM.sortRows(oldIndex, newIndex)}
      />
    </div>
  )
}

export default inject('sessionsStore')(observer(SetsSortableList))
