import { observable, action, computed, makeObservable, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Exercise } from '../aggregate/Exercise'
import { IExerciseDTO } from '../dtos/IExerciseDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import { makePersistable } from 'mobx-persist-store'
import AgentV2 from '../../AgentV2'
import { deserialize } from 'serializr'
import { ExerciseEditVM } from '../view-models/edit/ExerciseEditVM'
import { ExercisesListVM } from '../view-models/list/ExercisesListVM'

export class ExercisesStore extends DataStore<RootStore, Exercise, IExerciseDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, Exercise, AgentV2, 'Exercises', 'Exercise')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'ExercisesStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydrated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(Exercise, e))))
    super.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydrated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.listVM = new ExercisesListVM(this.rootStore as RootStore)
  }

  @observable.shallow public persistedRecords: Array<Exercise> = []
  @observable public editVM: ExerciseEditVM = null
  @observable public listVM: ExercisesListVM = null
  @observable public isHydrated: boolean = false

  public onLoadDataCompleted(): void {
    if (!this.listVM) return
    this.listVM.reloadRows()
  }

  @action
  public lazyLoadEditVM(exerciseGuid: string, attempts: number = 0) {
    if (attempts === 10) return
    if (!this.rootStore.boardsStore.isHydrated) {
      setTimeout(() => this.lazyLoadEditVM(exerciseGuid, attempts++), 500)
      return
    }
    if (exerciseGuid === 'new') {
      this.editVM = new ExerciseEditVM(
        this.rootStore,
        Exercise.create(this.rootStore.boardsStore.currentBoardId)
      )
    } else if (exerciseGuid === 'empty') {
      this.editVM = null
    } else {
      const exercise = this.get(exerciseGuid)
      if (!exercise) {
        setTimeout(() => this.lazyLoadEditVM(exerciseGuid, attempts++), 500)
        return
      }
      this.editVM = new ExerciseEditVM(this.rootStore, exercise.clone())
    }
  }

  @computed
  public get currentBoardRecords() {
    if (!this.isHydrated) return []
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  public onAfterUpdate(ex: Exercise): void {
    this.refreshEditVMFromExercise(ex)
  }

  private refreshEditVMFromExercise(ex: Exercise) {
    if (!this.editVM) return
    if (this.editVM.exerciseGuid !== ex.ExerciseGuid) return
    this.editVM.loadExercise(ex, false)
  }
}
