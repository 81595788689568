import React from 'react'
import { inject, Observer, observer } from 'mobx-react'
import { IonCheckbox, IonIcon, IonInput, IonItem } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { SessionsStore } from '../../store/SessionsStore'
import { SetRowVM } from '../../view-models/edit/SetRowVM'
import { SetGroupRowVM } from '../../view-models/edit/SetGroupRowVM'
import { chevronDown, chevronForward, timerSharp } from 'ionicons/icons'
import { MaterialCommunityIcons } from 'react-web-vector-icons'
import { AppStore } from '../../../stores/AppStore'

interface Props {
  sessionsStore?: SessionsStore
  appStore?: AppStore
}

const SetsList: React.FC<Props> = ({ sessionsStore, appStore }) => {
  const editVM = sessionsStore.editVM
  if (!editVM) return null
  const listVM = editVM.setsListVM
  if (!listVM) return null
  if (editVM.editMode) return null

  const renderTimer = (row: SetRowVM) => {
    const renderZeroCircle = () => {
      if (row.restTimePercentCompleteOf7 !== 0) return
      if (row.restSecondsRemaining === 0) return
      if (!row.restSecondsRemaining) return
      return <MaterialCommunityIcons name={'circle-outline'} size={22} />
    }

    const renderProgressCircle = () => {
      if (row.restTimePercentCompleteOf7 === 0) return
      if (row.restSecondsRemaining === 0) return
      return <MaterialCommunityIcons name={'circle-slice-' + row.restTimePercentCompleteOf7} size={22} />
    }

    const renderCheckCircle = () => {
      if (row.restTimePercentCompleteOf7 === 0) return
      if (row.restSecondsRemaining !== 0) return
      return <MaterialCommunityIcons name={'check-circle'} size={22} />
    }

    return (
      <Observer>
        {() => (
          <div key={String(listVM.tick + row.key)} className='timer'>
            <div className='seconds'>{row.restSecondsRemainingDisplay}</div>
            {renderZeroCircle()}
            {renderCheckCircle()}
            {renderProgressCircle()}
          </div>
        )}
      </Observer>
    )
  }

  const renderReps = (row: SetRowVM) => {
    return (
      <div className='reps-cell'>
        <IonInput
          value={row.actualReps}
          type='number'
          onIonChange={(e: any) => row.setActualReps(e.target.value)}
          onFocus={(e: any) => e.target.select()}
        />
      </div>
    )
  }

  const renderActualValue = (row: SetRowVM) => {
    return (
      <IonInput
        value={row.actualValue}
        type='number'
        onIonChange={(e: any) => row.setActualValue(e.target.value)}
        onFocus={(e: any) => e.target.select()}
      />
    )
  }

  const renderNormalRow = (row: SetRowVM, index, style) => {
    const style2 = {
      ...style,
      borderLeft: 'none',
    }
    return (
      <div className='normal-row' onClick={() => row.click()} style={style2} key={row.key}>
        <IonCheckbox mode='ios' color='tertiary' checked={row.isCompleted} onClick={() => row.toggleCompleted()} />
        <div className='main-cell'>{row.displayName}</div>
        <div className='reps-cell'>{renderReps(row)}</div>
        <div className='right-cell'>{renderActualValue(row)}</div>
        <div className='rest-cell'>{renderTimer(row)}</div>
      </div>
    )
  }

  const renderRightIcon = (row: SetGroupRowVM) => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = (row: SetGroupRowVM) => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  const renderGroupRow = (row: SetGroupRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.isCollapsed + row.isInvView}>
        {() => (
          <div style={style} className={row.rowType} onClick={() => row.click()}>
            <div className='main-cell'>{row.displayName}</div>
            <div className='exercise-description'>{row.exerciseDescription}</div>
            {renderRightIcon(row)}
            {renderDownIcon(row)}
          </div>
        )}
      </Observer>
    )
  }

  return (
    <List
      listVM={listVM}
      className='list'
      onRef={(e) => appStore.applyScrollStyles(e)}
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
    />
  )
}

export default inject('sessionsStore', 'appStore')(observer(SetsList))
