import { observable, action, makeObservable, computed } from 'mobx'
import generateUUID from '../../utils/UUID'
import { IExerciseDTO } from '../dtos/IExerciseDTO'
import { serialize, deserialize, serializable, object, list } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { Scheme } from './Scheme'

export class Exercise implements IExerciseDTO, IAggregrate {
  public static create(boardId: number) {
    const rem = new Exercise()
    rem.ExerciseGuid = generateUUID()
    rem.BoardId = boardId
    return rem
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public ExerciseGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public Notes: string = ''
  @serializable @observable public BoardId: number = 0
  @serializable @observable public OneRepMax: number = undefined
  @serializable(list(object(Scheme))) @observable public Schemes: Scheme[] = []
  @serializable @observable public IsDeleted: boolean = false

  @action
  public setName(val: string) {
    this.Name = val
  }

  @action
  public setOneRepMax(val: number) {
    this.OneRepMax = val
  }

  @action
  public setNotes(val: string) {
    this.Notes = val
  }

  @computed
  public get hasNotes(): boolean {
    return Boolean(this.Notes)
  }

  @action
  public addScheme(sch: Scheme = undefined) {
    if (!sch) sch = Scheme.create()
    this.Schemes.push(sch)
    return sch
  }

  public getScheme(schemeGuid: string) {
    return this.Schemes.find((e) => e.SchemeGuid === schemeGuid)
  }

  public clone(): Exercise {
    return deserialize(Exercise, serialize(this))
  }

  public toDTO(): IExerciseDTO {
    return serialize(this)
  }
}
