import { GroupRowVM } from '@elexient/elexiapp.bits.shared'
import { computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { SetRowVM } from './SetRowVM'
import { SetsListVM } from './SetsListVM'

export class SetGroupRowVM extends GroupRowVM<RootStore, SetsListVM> {
  @computed
  public get displayName(): string {
    return this.name.substring(3)
  }

  public get color(): string {
    return '#c00000'
  }

  public get height(): number {
    return 40
  }

  public get exerciseDescription(): string {
    const record = this.listVM.allRows.find(e => e.rowType === 'normal' && e.parentGroup1 === this) as SetRowVM
    return record.exerciseDescription
  }
}
