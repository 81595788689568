import React from 'react'
import { inject, observer } from 'mobx-react'
import { IonItem } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { ExercisesStore } from '../../store/ExercisesStore'
import { SchemeRowVM } from '../../view-models/edit/SchemeRowVM'

interface Props {
  exercisesStore?: ExercisesStore
}

const SchemesList: React.FC<Props> = ({ exercisesStore }) => {
  const editVM = exercisesStore.editVM
  if (!editVM) return null
  if (editVM.currentTabIndex !== '1') return null
  const listVM = editVM.schemesListVM
  if (!listVM) return null

  const renderNormalRow = (row: SchemeRowVM, index, style) => {
    const style2 = {
      ...style,
      borderLeft: 'none',
    }
    return (
      <IonItem key={row.key} lines='none' className={'normal-row'} onClick={() => row.click()} style={style2}>
        <div className='main-cell'>{row.displayName}</div>
        <div className='right'>{row.restDisplay}</div>
      </IonItem>
    )
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => undefined}
    />
  )
}

export default inject('exercisesStore')(observer(SchemesList))
