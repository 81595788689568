import { computed, action, observable } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { RootStore } from '../../../stores/RootStore'
import { SchemesListVM } from './SchemesListVM'
import { Scheme } from '../../../workouts/aggregate/Scheme'
import { WeightTypeEnum } from '../../../exercises/enums/WeightTypeEnum'

export class SchemeRowVM extends RowVM<RootStore, SchemesListVM, Scheme> {
  constructor(rootStore: RootStore, listVM: SchemesListVM, scheme: Scheme, self: any) {
    super(rootStore, listVM, scheme, self)
  }

  public get height(): number {
    if (this.isHidden) return 0
    return 50
  }

  @computed
  public get keyword(): string {
    return ''
  }

  public get key(): string {
    return this.record.SchemeGuid
  }

  public get exerciseName(): string {
    const exercise = this.rootStore.exercisesStore.get(this.record.ExerciseGuid)
    return exercise.Name
  }

  public get displayName(): string {
    let val = String(this.record.Sets)
    val = val + 'x' + this.record.Reps
    if (this.weightDisplayName) val = val + '@' + this.weightDisplayName
    return val
  }

  private get weightDisplayName(): string {
    if (this.record.WeightTypeId === WeightTypeEnum.Percentage_of_One_Rep_Max) {
      return this.record.PercentageOfOneRepMax + 'RM'
    }
    if (!this.record.Weight) return undefined
    return String(this.record.Weight)
  }

  public get restDisplay(): string {
    return this.record.Rest + 's'
  }

  @action
  public click() {
    this.rootStore.workoutsStore.editVM.itemEditVM.setExercise(this.record.ExerciseGuid, this.record.SchemeGuid)
  }
}
