import { GroupRowVM } from '@elexient/elexiapp.bits.shared'
import { computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { ExercisesListVM } from './ExercisesListVM'

export class ExerciseGroupRowVM extends GroupRowVM<RootStore, ExercisesListVM> {
  @computed
  public get displayName(): string {
    return this.name.replace('_', '')
  }

  public get color(): string {
    return '#c00000'
  }

  public get height(): number{
    return 40
  }
}
