import { RootStore } from '../../../stores/RootStore'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { action, makeObservable, observable, reaction } from 'mobx'
import { SchemeRowVM } from './SchemeRowVM'
import { Scheme } from '../../aggregate/Scheme'
import { ExerciseEditVM } from './ExerciseEditVM'
import { SchemeGroupRowVM } from './SchemeGroupRowVM'

export class SchemesListVM extends ListVM<RootStore, Scheme, SchemeGroupRowVM, SchemeRowVM, undefined> {
  private editVM: ExerciseEditVM

  constructor(rootStore: RootStore, editVM: ExerciseEditVM) {
    super(rootStore, SchemeRowVM, SchemeGroupRowVM, undefined)
    makeObservable(this)
    this.editVM = editVM
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    this.atTopCheckTimeout = 500
    this.setGroups('', '')
    this.addReaction(
      reaction(
        () => this.rootStore.appStore.listHeight,
        () => this.setHeight(this.rootStore.appStore.listHeight),
        { delay: 200 }
      )
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  protected getRecords() {
    return this.editVM.exercise.Schemes
  }

  protected sortFunction = (a: Scheme, b: Scheme): number => {
    const aVal = a.Sets
    const bVal = b.Sets
    if (aVal > bVal) return -1
    return 0
  }

  @action
  public addScheme() {
    // this.editVM
  }
}
