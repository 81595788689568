import { observable, action, makeObservable, computed } from 'mobx'
import generateUUID from '../../utils/UUID'
import { serialize, deserialize, serializable, object, list } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { Set } from './Set'
import { ISessionDTO } from '../dtos/ISessionDTO'
import moment from 'moment'
import { SessionStatusEnum } from '../enums/SessionStatusEnum'
import { SessionExercise } from './SessionExercise'
import { ISessionExerciseDTO } from '../dtos/ISessionExerciseDTO'
import { BasedOnTypeEnum } from '../../exercises/enums/BasedOnTypeEnum'

export class Session implements ISessionDTO, IAggregrate {
  public static create(boardId: number) {
    const rem = new Session()
    rem.SessionGuid = generateUUID()
    rem.BoardId = boardId
    return rem
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public SessionGuid: string
  @serializable @observable public BoardId: number = 0
  @serializable @observable public WorkoutGuid: string
  @serializable @observable public Name: string = ''
  @serializable @observable public SessionDate: string = ''
  @serializable @observable public SessionStatusId: number = SessionStatusEnum.Pending
  @serializable @observable public Notes: string = ''
  @serializable(list(object(SessionExercise))) @observable public SessionExercises: SessionExercise[] = []
  @serializable @observable public IsDeleted: boolean = false

  @action
  public setName(val: string) {
    this.Name = val
  }

  @action
  public setNotes(val: string) {
    this.Notes = val
  }

  @action
  public setSessionDate(val: moment.Moment) {
    if (!val.isUTC) val.utc()
    this.SessionDate = val.toISOString()
  }

  @computed
  public get localSessionDate(): moment.Moment {
    return moment.utc(this.SessionDate).local().clone()
  }

  @computed
  public get hasNotes(): boolean {
    return Boolean(this.Notes)
  }

  public addExercise(dto: ISessionExerciseDTO) {
    const ex = SessionExercise.createFromDTO(dto)
    this.SessionExercises.push(ex)
    return ex
  }

  @action
  public toggleExerciseSetCompleted(sessionExerciseGuid: string, setGuid: string) {
    this.SessionStatusId = SessionStatusEnum.In_Progress
    const ex = this.getSessionExercise(sessionExerciseGuid)
    if (!ex) return
    ex.toggleCompleted(setGuid)
    this.checkForSessionCompleted()
  }

  @action
  public setSetActualValue(sessionExerciseGuid: string, setGuid: string, val: number) {
    const ex = this.getSessionExercise(sessionExerciseGuid)
    if (!ex) return
    const set = ex.getSet(setGuid)
    if (!set) return
    if (ex.BasedOnTypeId === BasedOnTypeEnum.Time) {
      set.setActualTime(val)
      return
    }
    set.setActualWeight(val)
  }

  @action
  public setSetActualReps(sessionExerciseGuid: string, setGuid: string, val: number) {
    const ex = this.getSessionExercise(sessionExerciseGuid)
    if (!ex) return
    const set = ex.getSet(setGuid)
    if (!set) return
    set.setActualReps(val)
  }

  private checkForSessionCompleted() {
    const hasUncompleted = Boolean(this.SessionExercises.find((e) => e.IsCompleted === false))
    if (hasUncompleted) return
    this.SessionStatusId = SessionStatusEnum.Completed
  }

  @computed
  public get percentComplete() {
    const total = this.allSets.length
    const completed = this.allSets.filter((e) => e.IsCompleted === true).length
    return completed / total
  }

  private get allSets() {
    return this.SessionExercises.map((e) => e.Sets).flat(1)
  }

  @action
  public setStatus(val: number) {
    this.SessionStatusId = val
  }

  public getSessionExercise(sessionExerciseGuid: string) {
    return this.SessionExercises.find((e) => e.SessionExerciseGuid === sessionExerciseGuid)
  }

  public clone(): Session {
    return deserialize(Session, serialize(this))
  }

  public toDTO(): ISessionDTO {
    return serialize(this)
  }
}
