import { ActionsStore } from '../store/ActionsStore'
import { Action } from '../aggregate/Action'
import { ActionTypeEnum } from '../enum/ActionTypeEnum'
import { RootStore } from '../../stores/RootStore'
import { action, computed, observable } from 'mobx'
import * as Sentry from '@sentry/browser'
import { BoardsService } from '../../boards/service/BoardsService'
import { BoardInvitationsService } from '../../board-invitations/service/BoardInvitationsService'
import { ExercisesService } from '../../exercises/services/ExercisesService'
import { WorkoutsService } from '../../workouts/services/WorkoutsService'
import { SessionsService } from '../../sessions/services/SessionsService'

export class ActionsService {
  private actionsStore: ActionsStore
  private rootStore: RootStore

  constructor(rootStore: RootStore, actionsStore: ActionsStore) {
    this.rootStore = rootStore
    this.actionsStore = actionsStore
  }

  @observable public isProcessing: boolean = false

  @computed
  private get actions(): Array<Action> {
    return this.actionsStore.actions
  }

  @action
  public async processActions() {
    if (window && window.Offline && window.Offline.state === 'down') return
    if (this.actions.length === 0) return
    if (this.isProcessing) return
    this.isProcessing = true
    const nextAction = this.actions[0]
    if (nextAction.attempts > 5) {
      this.actions.shift()
      this.isProcessing = false
      return
    }
    console.log('Processing action: ' + nextAction.typeName + ' dto:' + nextAction.dtoString)
    let success = false
    try {
      if (nextAction.type === ActionTypeEnum.BOARD_SAVE) await this.processBoardSave(nextAction)
      if (nextAction.type === ActionTypeEnum.BOARDINVITATION_SAVE) await this.processBoardInvitationSave(nextAction)
      if (nextAction.type === ActionTypeEnum.EXERCISE_SAVE) await this.processExerciseSave(nextAction)
      if (nextAction.type === ActionTypeEnum.WORKOUT_SAVE) await this.processWorkoutSave(nextAction)
      if (nextAction.type === ActionTypeEnum.SESSION_SAVE) await this.processSessionSave(nextAction)
      success = true
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
    if (success) {
      this.actions.shift()
      if (process.env.NODE_ENV === 'test') this.processActions()
      else setTimeout(() => this.processActions(), 1)
    } else {
      nextAction.attempts += 1
    }
    this.isProcessing = false
  }

  private async processBoardSave(nextAction: Action) {
    const svc = new BoardsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processBoardInvitationSave(nextAction: Action) {
    const svc = new BoardInvitationsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processExerciseSave(nextAction: Action) {
    const svc = new ExercisesService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processWorkoutSave(nextAction: Action) {
    const svc = new WorkoutsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processSessionSave(nextAction: Action) {
    const svc = new SessionsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }
}
