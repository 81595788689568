import { observable, makeObservable, action, computed } from 'mobx'
import { serialize, serializable, deserialize } from 'serializr'
import { ISetDTO } from '../dtos/ISetDTO'
import uuid from 'uuid'
import { IDTO } from '../../shared/IDTO'
import moment, { ISO_8601 } from 'moment'

export class Set implements ISetDTO, IDTO {
  public static createFromDTO(dto: ISetDTO): Set {
    const it = deserialize(Set, dto)
    it.SetGuid = uuid()
    return it
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public SetGuid: string
  @serializable @observable public SessionExerciseGuid: string
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public SetNumber: number = 0
  @serializable @observable public TargetWeight: number = 0
  @serializable @observable public TargetTime: number = 0
  @serializable @observable public TargetRestTime: number = 0
  @serializable @observable public TargetReps: number = 0
  @serializable @observable public ActualWeight: number = 0
  @serializable @observable public ActualTime: number = 0
  @serializable @observable public ActualReps: number = 0
  @serializable @observable public IsCompleted: boolean = false
  @serializable @observable public CompletedDateTime: string = undefined

  @action
  public toggleCompleted() {
    this.IsCompleted = !this.IsCompleted
    if (!this.IsCompleted) this.CompletedDateTime = undefined
    this.CompletedDateTime = moment().add(1, 'second').startOf('second').toISOString()
  }

  @computed
  public get RestFinishDateTime() {
    if (!this.IsCompleted) return undefined
    const dt = moment.utc(this.CompletedDateTime, ISO_8601).local()
    dt.add(this.TargetRestTime, 'seconds')
    return dt.clone().toDate()
  }

  public get RestSecondsRemaining() {
    if (!this.IsCompleted) return undefined
    if (!this.CompletedDateTime) return undefined
    const secs = moment(this.RestFinishDateTime, ISO_8601).diff(moment(), 'seconds')
    if (secs < 0) return 0
    return secs
  }

  public get RestTimePercentComplete() {
    if (!this.IsCompleted) return undefined
    if (!this.CompletedDateTime) return undefined
    if (this.RestSecondsRemaining > this.TargetRestTime) return 100
    return (this.RestSecondsRemaining / this.TargetRestTime) * 100
  }

  @action
  public setActualWeight(val: number) {
    this.ActualWeight = Number(val)
  }

  @action
  public setActualTime(val: number) {
    this.ActualTime = val
  }
  
  @action
  public setActualReps(val: number) {
    this.ActualReps = Number(val)
  }

  @action
  public markAsDeleted(): void {
    this.IsDeleted = true
  }

  public toDTO(): ISetDTO {
    return serialize(this)
  }
}
