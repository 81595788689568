import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { SelectableColors } from '../../../shared/SelectableColors'
import { Exercise } from '../../aggregate/Exercise'
import { Scheme } from '../../aggregate/Scheme'
import { ExercisesService } from '../../services/ExercisesService'
import { SchemeEditVM } from './SchemeEditVM'
import { SchemesListVM } from './SchemesListVM'

export class ExerciseEditVM {
  private rootStore: RootStore
  private contentRef: HTMLIonContentElement

  constructor(rootStore: RootStore, exercise: Exercise, isNew: boolean = false) {
    makeObservable(this)
    this.rootStore = rootStore
    this.loadExercise(exercise, isNew)
    this.schemesListVM = new SchemesListVM(this.rootStore, this)
  }

  @observable public deleteConfirmShown: boolean = false
  @observable public dayOfMonth: number = 1
  public isNew: boolean = false
  @observable public currentTabIndex: string = '0'
  public exercise: Exercise
  public schemesListVM: SchemesListVM
  @observable public schemeEditVM: SchemeEditVM
  @observable public saveFabShown: boolean = true
  @observable public addSchemeFabShown: boolean = false

  @action
  public addScheme() {
    const sch = Scheme.create()
    this.schemeEditVM = new SchemeEditVM(this.rootStore, this, sch, true)
  }

  @action
  public addSavedScheme(scheme: Scheme) {
    this.exercise.addScheme(scheme)
  }

  @action
  public openScheme(scheme) {
    this.schemeEditVM = new SchemeEditVM(this.rootStore, this, scheme)
  }

  public get exerciseGuid(): string {
    return this.exercise.ExerciseGuid
  }

  @action
  public loadExercise(exercise: Exercise, isNew: boolean) {
    this.exercise = exercise.clone()
    this.isNew = Boolean(isNew)
  }

  @action
  public setName(val) {
    this.exercise.setName(val)
  }

  @computed
  public get name(): string {
    return this.exercise.Name
  }

  @action
  public setNotes(val) {
    this.exercise.setNotes(val)
  }

  @computed
  public get notes(): string {
    return this.exercise.Notes
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @computed
  public get pageTitle(): string {
    if (this.isNew) {
      let str = 'New Exercise'
      return str
    }
    return 'Edit Exercise'
  }

  @action
  public save() {
    const svc = new ExercisesService(this.rootStore)
    svc.save(this.exercise.toDTO())
    this.rootStore.appStore.history.push('/exerciseslist')
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  public delete() {
    const svc = new ExercisesService(this.rootStore)
    svc.delete(this.exercise.toDTO())
    this.rootStore.appStore.handleGoBack()
  }

  @action
  public goBack() {
    this.rootStore.appStore.handleGoBack()
  }

  public get colors() {
    return SelectableColors.colors
  }

  @computed
  public get oneRepMax(): number {
    return this.exercise.OneRepMax
  }

  @action
  public setOneRepMax(val: number) {
    this.exercise.setOneRepMax(val)
  }

  public setContentRef(e: HTMLIonContentElement) {
    this.contentRef = e
  }

  @action
  public setCurrentTab(val: string) {
    if (this.currentTabIndex === val) return
    this.currentTabIndex = val
    this.contentRef.scrollToTop()
    this.saveFabShown = false
    this.addSchemeFabShown = false
    if (val === '0') this.saveFabShown = true
    if (val === '1') this.addSchemeFabShown = true
  }

  public setNameInput(e: HTMLIonInputElement) {
    if (!e) return
    e.setAttribute('autocomplete', 'something-new-2' + new Date().getTime())
  }
}
