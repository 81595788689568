import React from 'react'
import { inject, Observer, observer } from 'mobx-react'
import { IonIcon } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { chevronDown, chevronForward } from 'ionicons/icons'
import { SessionsStore } from '../../store/SessionsStore'
import { SessionRowVM } from '../../view-models/list/SessionRowVM'
import { SessionGroupRowVM } from '../../view-models/list/SessionGroupRowVM'
import SessionRow from './SessionRow'

interface Props {
  sessionsStore?: SessionsStore
}

const SessionsList: React.FC<Props> = ({ sessionsStore }) => {
  const listVM = sessionsStore.listVM
  if (!listVM) return null

  const renderNormalRow = (row: SessionRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType}>
        {() => <SessionRow style={style} key={row.key} row={row} index={index} />}
      </Observer>
    )
  }

  const renderRightIcon = (row: SessionGroupRowVM) => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = (row: SessionGroupRowVM) => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  const renderGroupRow = (row: SessionGroupRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.isCollapsed + row.isInvView}>
        {() => (
          <div style={style} className={row.rowType} onClick={() => row.click()}>
            <div className='main-cell'>{row.displayName}</div>
            {renderRightIcon(row)}
            {renderDownIcon(row)}
          </div>
        )}
      </Observer>
    )
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
    />
  )
}

export default inject('sessionsStore')(observer(SessionsList))
