import { computed, action } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { Session } from '../../aggregate/Session'
import { SessionsListVM } from './SessionsListVM'
import { RootStore } from '../../../stores/RootStore'
import { SessionStatusEnumName } from '../../enums/SessionStatusEnumName'
import Sugar from 'sugar'

export class SessionRowVM extends RowVM<RootStore, SessionsListVM, Session> {
  constructor(rootStore: RootStore, listVM: SessionsListVM, exercise: Session, self: any) {
    super(rootStore, listVM, exercise, self)
  }

  @computed
  public get keyword(): string {
    return this.record.Name
  }

  @computed
  public get name(): string {
    return this.record.Name
  }

  @computed
  public get sessionDate(): string {
    const dt = this.record.localSessionDate.toDate().toLocaleDateString('en-US')
    const dayOfWeek = this.record.localSessionDate.toDate().toLocaleDateString('en-US', { weekday: 'long' })
    return dayOfWeek + ' ' + dt
  }

  @computed
  public get plannerGroup(): string {
    let group = '3Past'
    if (this.record.localSessionDate.isAfter(new Date())) group = '1Upcoming'
    if (this.record.localSessionDate.isSame(new Date(), 'day')) group = '0Today'
    return group
  }

  @computed
  public get key(): string {
    return this.record.SessionGuid
  }

  @action
  public click() {
    this.rootStore.appStore.history.push('/sessionedit/' + this.key)
  }

  @computed
  public get notes(): string {
    return this.record.Notes
  }

  @computed
  public get statusName(): string {
    return SessionStatusEnumName.fromId(this.record.SessionStatusId)
  }

  @computed
  public get statusId(): number {
    return this.record.SessionStatusId
  }

  @computed
  public get percentCompleteOf7() {
    return Math.round(this.record.percentComplete * 8)
  }

  @computed
  public get hasNotes(): boolean {
    return this.record.hasNotes
  }

  public get height(): number {
    if (this.isHidden) return 0
    return 44
  }
}
