import { observable, action, makeObservable, computed } from 'mobx'
import generateUUID from '../../utils/UUID'
import { serialize, deserialize, serializable, object, list } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { IWorkoutDTO } from '../dtos/IWorkoutDTO'
import { Item } from './Item'

export class Workout implements IWorkoutDTO, IAggregrate {
  public static create(boardId: number) {
    const rem = new Workout()
    rem.WorkoutGuid = generateUUID()
    rem.BoardId = boardId
    return rem
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public WorkoutGuid: string
  @serializable @observable public BoardId: number = 0
  @serializable @observable public Name: string = ''
  @serializable @observable public Notes: string = ''
  @serializable(list(object(Item))) @observable public Items: Item[] = [] // rename to workoutexercises
  @serializable @observable public IsDeleted: boolean = false

  @action
  public setName(val: string) {
    this.Name = val
  }

  @action
  public setNotes(val: string) {
    this.Notes = val
  }

  @computed
  public get hasNotes(): boolean {
    return Boolean(this.Notes)
  }

  @action
  public addItem(item: Item) {
    const rank = this.Items.length
    item.setRank(rank)
    this.Items.push(item)
    return item
  }

  @action
  public deleteItem(itemGuid: string) {
    const item = this.Items.find(e => e.ItemGuid === itemGuid)
    if (!item) return
    item.markAsDeleted()
  }

  public clone(): Workout {
    return deserialize(Workout, serialize(this))
  }

  public toDTO(): IWorkoutDTO {
    return serialize(this)
  }
}
