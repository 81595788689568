import React from 'react'
import {
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle,
  IonItemDivider,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { checkmark, close, arrowBack, checkmarkSharp } from 'ionicons/icons'
import BackIcon from 'src/shared/BackIcon'
import '../styles/AccountSettingsPage.scss'
import { UserStore } from '../store/UserStore'
import GoogleLogin from './GoogleLogin'
import AppleLogin from './AppleLogin'

interface Props {
  appStore: AppStore
  userStore: UserStore
  history: any
}

@inject('appStore')
@inject('userStore')
@observer
export default class AccountSettingsPage extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.props.userStore.loadUserEditVM()
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/accountsettings')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  handleSavePress(e) {
    e.preventDefault()
    this.props.userStore.userEditVM.save()
  }

  renderPersonalInfo() {
    const { userEditVM: vm } = this.props.userStore
    if (!vm) return null
    return (
      <form onSubmit={this.handleSavePress.bind(this)}>
        <IonList mode='md'>
          <IonItemDivider color='medium' mode='md'>
            My Info
          </IonItemDivider>

          <IonItem lines={'inset'}>
            <IonLabel position='floating'>Name</IonLabel>
            <IonInput type='text' value={vm.Name} onIonChange={(e: any) => (vm.Name = e.target.value)} />
          </IonItem>

          <IonItem lines={'inset'}>
            <IonLabel position='floating'>Email</IonLabel>
            <IonInput
              type='text'
              value={vm.EmailAddress}
              onIonChange={(e: any) => (vm.EmailAddress = e.target.value)}
            />
          </IonItem>
        </IonList>
      </form>
    )
  }

  render() {
    const { userEditVM: form } = this.props.userStore
    if (!form) return null
    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='AccountSettingsPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>

            <IonTitle>My Account</IonTitle>

            <IonButtons slot='end'>
              <IonButton onClick={() => this.handleGoBack()}>
                <IonIcon icon={close} />
              </IonButton>
              <IonButton onClick={e => this.handleSavePress(e)} disabled={!form.isValid}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent id='AccountSettingsPage_Content' scrollY={false}>
          {this.renderPersonalInfo()}
          <GoogleLogin />
          <AppleLogin />
        </IonContent>

        <IonFab vertical='bottom' horizontal='end'>
          <IonFabButton
            color='tertiary'
            onClick={e => this.handleSavePress(e)}
            disabled={!form.isValid}
            style={{
              marginTop: '-60px',
              marginLeft: '-60px',
            }}
          >
            <IonIcon icon={checkmarkSharp} />
          </IonFabButton>
        </IonFab>
      </>
    )
  }
}
