import React from 'react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { RootStore } from '../../../stores/RootStore'
import { match } from 'react-router'
import { WorkoutsStore } from '../../store/WorkoutsStore'
import WorkoutEditArea from './WorkoutEditArea'

interface Props {
  workoutsStore: WorkoutsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('workoutsStore')
@inject('appStore')
@observer
export default class WorkoutEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute(this.props.match.url)
    this.props.workoutsStore.lazyLoadEditVM(this.props.match.params['id'])
  }

  componentWillUnmount() {
    this.props.workoutsStore.lazyLoadEditVM('empty')
  }

  render() {
    return <WorkoutEditArea />
  }
}
