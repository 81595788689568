import { computed, action, observable } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { RootStore } from '../../../stores/RootStore'
import { SetsListVM } from './SetsListVM'
import { Set } from '../../aggregate/Set'
import { Scheme } from '../../aggregate/Scheme'
import { Exercise } from '../../aggregate/Exercise'
import { Exercise as FullExercise } from '../../../exercises/aggregate/Exercise'
import { BasedOnTypeEnum } from '../../../exercises/enums/BasedOnTypeEnum'

export class SetRowVM extends RowVM<RootStore, SetsListVM, Set> {
  constructor(rootStore: RootStore, listVM: SetsListVM, item: Set, self: any) {
    super(rootStore, listVM, item, self)
  }

  public get height(): number {
    if (this.isHidden) return 0
    return 50
  }

  @computed
  public get keyword(): string {
    return ''
  }

  public get key(): string {
    return this.record.SetGuid
  }

  public get displayName(): string {
    return 'Set ' + this.record.SetNumber
  }

  public get modifyingDisplayName(): string {
    return this.exerciseName.substring(3) + this.exerciseDescription + 'Set ' + this.record.SetNumber
  }

  @computed
  public get sessionExercise() {
    return this.listVM.parentVM.session.getSessionExercise(this.record.SessionExerciseGuid)
  }

  public get exercise(): Exercise {
    if (!this.sessionExercise.Exercise) {
      return this.rootStore.exercisesStore.get(this.exerciseGuid)
    }
    return this.sessionExercise.Exercise as Exercise
  }

  public get exerciseGuid() {
    return this.sessionExercise.ExerciseGuid
  }

  public get exerciseName(): string {
    if (!this.exercise) return 'Loading'
    let val = String(this.sessionExercise.Rank)
    val = val + this.exercise.Name
    return val
  }

  public get exerciseDisplayName(): string {
    if (!this.exercise) return 'Loading'
    return this.exerciseName.substring(3)
  }

  public get setNumber(): number {
    return this.record.SetNumber
  }

  public get scheme(): Scheme {
    if (!this.sessionExercise.Scheme) {
      return (this.exercise as FullExercise).getScheme(this.sessionExercise.SchemeGuid) as unknown as Scheme
    }
    return this.sessionExercise.Scheme
  }

  public get exerciseDescription(): string {
    let info = String(this.scheme.Sets)
    info = info + 'x' + this.scheme.Reps
    info = info + '@' + this.scheme.Weight
    return info
  }

  @computed
  public get rank() {
    return Number(String(this.sessionExercise.Rank) + '0' + String(this.record.SetNumber)) //TODO: ACTUAL PAD
  }

  @computed
  public get hasReps(): boolean {
    return this.sessionExercise.BasedOnTypeId === BasedOnTypeEnum.Reps
  }

  @computed
  public get actualReps() {
    return this.record.ActualReps
  }

  @computed
  public get actualValue() {
    if (this.sessionExercise.BasedOnTypeId === BasedOnTypeEnum.Time) {
      return this.record.ActualTime
    }
    return this.record.ActualWeight
  }

  @computed
  public get borderColor(): string {
    if (!this.record.IsCompleted) return 'gray'
    return 'green'
  }

  @action
  public delete() {}

  @action
  public click() {}

  @computed
  public get isCompleted(): boolean {
    return this.record.IsCompleted
  }

  @action
  public toggleCompleted() {
    this.listVM.parentVM.toggleSetCompleted(this.record.SessionExerciseGuid, this.record.SetGuid)
  }

  @action
  public setActualValue(val: string) {
    this.listVM.parentVM.setSetActualValue(this.record.SessionExerciseGuid, this.record.SetGuid, Number(val))
  }

  @action
  public setActualReps(val: string) {
    this.listVM.parentVM.setSetActualReps(this.record.SessionExerciseGuid, this.record.SetGuid, Number(val))
  }

  public get restTimePercentCompleteOf7() {
    if (!this.record.IsCompleted) return 0
    if (this.record.RestSecondsRemaining === 0) return 8
    return 8 - Math.round(this.record.RestTimePercentComplete * 0.01 * 8)
  }

  @computed
  public get targetRestTime() {
    return this.record.TargetRestTime
  }

  public get restSecondsRemaining() {
    if (!this.record.IsCompleted) return undefined
    return this.record.RestSecondsRemaining
  }

  public get restSecondsRemainingDisplay() {
    if (!this.record.IsCompleted) return ''
    if (this.record.RestSecondsRemaining <= 0) return ''
    return this.record.RestSecondsRemaining
  }
}
