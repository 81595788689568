import React from 'react'
import { IonRouterOutlet, IonPage, IonSplitPane } from '@ionic/react'
import { Route, withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../stores/AppStore'
import { RootStore } from '../stores/RootStore'
import { Plugins, Capacitor } from '@capacitor/core'
import SideMenu from '../side-menu/views/SideMenu'
import AccountSettingsPage from '../user/views/AccountSettingsPage'
import Header from '../header/Header'
import BoardSettingsPage from '../boards/views/BoardSettingsPage'
import TabsBar from '../bottom-tabs/BottomTabs'
import ThemeGenPage from '../theme-gen/ThemeGenPage'
import ThemesListPage from '../themes/views/ThemesListPage'
import BoardJoinModal from '../boards/views/BoardJoinModal'
import DashboardPage from '../dashboard/views/DashboardPage'
import ExercisesListPage from '../exercises/views/list/ExercisesListPage'
import ExerciseEditPage from '../exercises/views/edit/ExerciseEditPage'
import WorkoutsListPage from '../workouts/views/list/WorkoutsListPage'
import WorkoutEditPage from '../workouts/views/edit/WorkoutEditPage'
import SessionEditPage from '../sessions/views/edit/SessionEditPage'
import SessionsListPage from '../sessions/views/list/SessionsListPage'

interface Props {
  rootStore?: RootStore
  appStore?: AppStore
  history: any
  location: any
}
@inject('appStore', 'rootStore')
@observer
class Layout extends React.Component<Props, any> {
  ionMenu: any

  constructor(props) {
    super(props)
    this.props.appStore.setHistory(this.props.history)
  }

  componentWillUnmount() {
    if (Capacitor.platform !== 'web') {
      // Plugins.App.removeListener('backButton', () => this.handleGoBack())
      Plugins.App.removeAllListeners()
    }
  }

  componentDidMount() {
    this.checkAuth()
    if (Capacitor.platform !== 'web') {
      Plugins.App.addListener('backButton', () => this.handleGoBack())
    }
    setInterval(() => {
      if (this.props.appStore.bodyHeight === document.body.offsetHeight) return
      this.props.appStore.setBodyHeight(document.body.offsetHeight)
    }, 1000)
    window.onresize = () => {
      if (this.props.appStore.bodyHeight === document.body.offsetHeight) return
      this.props.appStore.setBodyHeight(document.body.offsetHeight)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.checkAuth()
      this.onRouteChanged(this.props.location)
    }
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  onRouteChanged(location) {
    this.props.appStore.setCurrentRoute(location.pathname)
  }

  checkAuth() {
    const currentRoute: string = this.props.appStore.currentRoute
    let isAnonymousPage = false
    if (currentRoute.indexOf('/tno') >= 0) isAnonymousPage = true
    if (currentRoute === '/login') isAnonymousPage = true
    if (currentRoute === '/register') isAnonymousPage = true
    if (currentRoute === '/forgot-password') isAnonymousPage = true
    if (currentRoute === '/reset-password') isAnonymousPage = true
    setTimeout(() => {
      const isHydrated = this.props.appStore.isHydrated
      const isLoggedIn = this.props.appStore.isLoggedIn
      if (isHydrated && !isLoggedIn && !isAnonymousPage) {
        console.log(currentRoute)
        console.log('going to login from: ' + currentRoute)
        this.props.appStore.setCurrentRoute('login')
        this.props.history.push('/login')
      } else if (!isHydrated) {
        setTimeout(this.checkAuth.bind(this), 1000)
      } else {
        this.props.appStore.hideSplashScreen()
        this.props.appStore.checkNativeStatusBarColor()
      }
    }, 1000)
  }

  render() {
    if (!this.props.appStore.isLoggedIn) return null
    if (this.props.location.pathname.toLowerCase() === '/login') return null
    if (this.props.location.pathname.toLowerCase() === '/register') return null
    if (this.props.location.pathname.toLowerCase() === '/forgot-password') return null

    const { isSplitPaneVisible } = this.props.appStore

    return (
      <>
        {isSplitPaneVisible && <Header history={this.props.history} />}

        <IonSplitPane
          contentId='main'
          id='Layout'
          onIonSplitPaneVisible={(e) => {
            const isVisible = e.detail.visible
            this.props.appStore.setSplitPaneVisible(isVisible)
          }}
        >
          <SideMenu />

          <IonPage id='main'>
            {!isSplitPaneVisible && <Header history={this.props.history} />}
            <IonRouterOutlet>
              <Route path={'/'} component={DashboardPage} exact />
              <Route path='/accountsettings' component={AccountSettingsPage} exact />
              <Route path='/boardsettings' component={BoardSettingsPage} exact />
              <Route path='/themegen' component={ThemeGenPage} exact />
              <Route path='/themeslist' component={ThemesListPage} exact />
              <Route path='/exerciseslist' component={ExercisesListPage} exact />
              <Route path='/exerciseedit/:id' component={ExerciseEditPage} exact />
              <Route path='/workoutslist' component={WorkoutsListPage} exact />
              <Route path='/sessionslist' component={SessionsListPage} exact />
              <Route path='/workoutedit/:id' component={WorkoutEditPage} exact />
              <Route path='/sessionedit/:id' component={SessionEditPage} exact />
            </IonRouterOutlet>
            <TabsBar />
            <BoardJoinModal />
          </IonPage>
        </IonSplitPane>
      </>
    )
  }
}

export default withRouter(Layout)
