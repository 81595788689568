import React from 'react'
import { inject, Observer, observer } from 'mobx-react'
import { IonIcon } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { chevronDown, chevronForward } from 'ionicons/icons'
import { WorkoutsStore } from '../../store/WorkoutsStore'
import { WorkoutRowVM } from '../../view-models/list/WorkoutRowVM'
import { WorkoutGroupRowVM } from '../../view-models/list/WorkoutGroupRowVM'

interface Props {
  workoutsStore?: WorkoutsStore
}

const WorkoutsList: React.FC<Props> = ({ workoutsStore }) => {
  const listVM = workoutsStore.listVM
  if (!listVM) return null

  const renderNormalRow = (row: WorkoutRowVM, index, style) => {
    const style2 = {
      ...style,
    }

    return (
      <Observer key={row.key + String(index) + row.rowType}>
        {() => (
          <div style={style2} key={row.key} className='normal-row' onClick={() => row.click()}>
            <div className='payee'>
              <div>{row.name}</div>
              <div className={row.hasNotes ? 'rem-notes' : 'rem-notes no-notes'}>{row.notes}</div>
            </div>
          </div>
        )}
      </Observer>
    )
  }

  const renderRightIcon = (row: WorkoutGroupRowVM) => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = (row: WorkoutGroupRowVM) => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  const renderGroupRow = (row: WorkoutGroupRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.isCollapsed + row.isInvView}>
        {() => (
          <div style={style} className={row.rowType} onClick={() => row.click()}>
            <div className='main-cell'>{row.displayName}</div>
            {renderRightIcon(row)}
            {renderDownIcon(row)}
          </div>
        )}
      </Observer>
    )
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
    />
  )
}

export default inject('workoutsStore')(observer(WorkoutsList))
