import React from 'react'
import { IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonButton, IonLabel } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { Plugins, Capacitor } from '@capacitor/core'
import AccountMenu from '../user/views/AccountMenu'
import BoardsMenu from '../boards/views/BoardsMenu'
import { MaterialIcons, MaterialCommunityIcons, Octicons } from 'react-web-vector-icons'
import { close } from 'ionicons/icons'
import './Header.scss'

@inject('appStore')
@inject('boardsStore')
@observer
export default class Header extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      optionsMenuShown: false,
    }
  }

  componentWillUnmount() {
    if (Capacitor.platform !== 'web') {
      // Plugins.App.removeListener('backButton', this.handleGoBack.bind(this)) //
      Plugins.App.removeAllListeners()
    }
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  handleSyncClick() {
    this.setState({
      optionsMenuShown: false,
    })
  }

  handleOptionsMenuClick(event) {
    event.persist()
    this.setState({ optionsMenuShown: true, event: event })
  }

  renderMenuButton() {
    if (this.props.appStore.showBackButton) return null
    if (this.props.appStore.isMenuOpen) return null
    if (this.props.appStore.isSplitPaneVisible) return null
    return <IonMenuButton />
  }

  renderCloseButton() {
    if (!this.props.appStore.isMenuOpen) return null
    return (
      <IonButton onClick={() => this.props.appStore.closeSideMenu()}>
        <IonIcon icon={close} />
      </IonButton>
    )
  }

  renderTitle() {
    if (!this.props.boardsStore.currentBoard) return
    return <BoardsMenu />
  }

  renderMenuButtons() {
    if (!this.props.appStore.isSplitPaneVisible) return null
    return (
      <>
        <IonButton onClick={() => this.props.history.push('/')}>
          <MaterialIcons name='local-grocery-store' size={24} color={'white'} />
        </IonButton>
        <IonButton onClick={() => this.props.history.push('/mealslist')}>
          <MaterialCommunityIcons name='food-fork-drink' size={24} color={'white'} />
        </IonButton>
        <IonButton onClick={() => this.props.history.push('/recipeslist')}>
          <Octicons name='book' size={24} color={'white'} />
        </IonButton>
      </>
    )
  }

  render() {
    if (!this.props.appStore.showAppHeader) return null
    const menuButton = this.renderMenuButton()
    const closeButton = this.renderCloseButton()
    return (
      <IonHeader id='Header'>
        <IonToolbar
          color='primary'
          id='HeaderToolbar'
          ref={() => setTimeout(() => this.props.appStore.computeAppHeaderHeight(), 500)}
        >
          {menuButton || closeButton ? (
            <IonButtons slot='start'>
              {menuButton}
              {closeButton}
            </IonButtons>
          ) : null}

          {this.renderTitle()}

          <IonButtons slot='end'>
            {/* {this.renderMenuButtons()} */}
            {/* <BoardMenu history={this.props.history} /> */}
            <AccountMenu history={this.props.history} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    )
  }
}
